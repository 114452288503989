import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { NewActivity } from 'common/components/email';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/tableGeneral';

import {
  getActionDetail,
  getSegmentedUsers,
  sendTestMail,
  sendMailParticipants,
} from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React from 'react';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, getFormValues, reduxForm, Field } from 'redux-form';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputSwitchGrey } from 'common/components/fields/InputSwitchGrey';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
class ShareToParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.props.getSegmentedUsers(
      this.props.action.id,
      this.props.auth.user.establishments[0].stations[0].id
    );
    this.state = {
      open: false,
      values: {},
      columns: [
        { field: 'name', title: 'Nome' },
        { field: 'email', title: 'E-mail' },
      ],
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSubmit = (values) => {
    this.setState({ open: true, values });
  };

  handleSendPush = () => {
    const { sendMailParticipants } = this.props;
    const { alternativeSubject, alternativeSummary } = this.state.values;
    const actionId = this.props.action.id;
    const actionImage = this.props.action.image;
    sendMailParticipants(actionId, alternativeSummary, alternativeSubject, actionImage);
    this.handleClose();
  };

  render() {
    const { handleSubmit, disabled, formValues, general } = this.props;
    const { columns, open } = this.state;
    const isLoading = this.props.activeActions.loadingSegmentedShare;
    const action = this.props.action;
    const station = this.props.auth.user.establishments[0].stations[0];
    const establishment = this.props.auth.user.establishments[0];
    const alternativeSummay = this.props.formValues && this.props.formValues.alternativeSummary;
    const { segmentedShare } = this.props.activeActions;
    const hasDescription = formValues && formValues.isThereDescription;
    const hasSubject = formValues && formValues.isThereAlternativeSubject;
    const isButtonLoading = this.props.activeActions.buttonLoading;

    if (isLoading) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <MaterialGrid container spacing={8}>
        <MaterialGrid item xs={6} style={{ borderRight: '1px solid lightgray' }}>
          <Typography style={{ textAlign: 'left', fontSize: 12, color: 'gray' }}>
            Pré-visualização
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: NewActivity(action, station, establishment, alternativeSummay),
            }}></div>
        </MaterialGrid>
        <MaterialGrid item xs={6}>
          <MaterialGrid item xs={12}>
            <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <MaterialGrid item xs={12}>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <Typography component="p">
                    {segmentedShare.length}
                    {segmentedShare.length > 1 || segmentedShare.length < 1
                      ? ' e-mails serão enviados'
                      : ' e-mail será enviado'}{' '}
                  </Typography>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Os emails serão enviados para todos os participantes que autorizaram o recebimento do e-mail (não solicitaram descadastramento), não ultrapassaram o limite de participações da atividade e estão dentro do segmento da atividade."
                    placement="top-start">
                    <FontIcon>help</FontIcon>
                  </Tooltip>
                </Box>
              </MaterialGrid>
              <MaterialGrid item xs={12} style={{ marginTop: 10 }}>
                <Table title="Extrato" columns={columns} data={segmentedShare} />
              </MaterialGrid>
              <MaterialGrid
                item
                xs={12}
                style={{
                  marginTop: 16,
                }}>
                <Field
                  component={InputSwitchGrey}
                  label={'Deseja adicionar sua própria descrição da atividade?'}
                  boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                  firstState="Ativado"
                  secondState="Selecione para escrever sua própria descrição para a atividade"
                  disabled={disabled}
                  name="isThereDescription"
                  margin="normal"
                />
              </MaterialGrid>
              {hasDescription && (
                <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                  <Field
                    component={EditorConvertToHTMLActivity}
                    label={'Descrição alternativa'}
                    name="alternativeSummary"
                    border
                  />
                </MaterialGrid>
              )}
              <MaterialGrid
                item
                xs={12}
                style={{
                  marginTop: 16,
                }}>
                <Field
                  component={InputSwitchGrey}
                  label={'Deseja adicionar seu próprio assunto do e-mail?'}
                  boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                  firstState="Ativado"
                  secondState="Selecione para escrever seu próprio assunto do e-mail. O padrão é: 'Olá, temos uma nova atividade para você'"
                  disabled={disabled}
                  name="isThereAlternativeSubject"
                  margin="normal"
                />
              </MaterialGrid>
              {hasSubject && (
                <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Assunto alternativo'}
                    name="alternativeSubject"
                  />
                </MaterialGrid>
              )}
              <MaterialGrid container justifyContent="flex-end">
                <PrimaryButton
                  type="submit"
                  color="primary"
                  disabled={isButtonLoading}
                  progress={parseInt(general.submitProgress)}>
                  Enviar E-mails
                </PrimaryButton>
              </MaterialGrid>
            </Form>
          </MaterialGrid>
        </MaterialGrid>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Enviar o e-mail?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao clicar em enviar, todos os usuários listados na tabela anterior serão notificados.
              Deseja continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.handleSendPush()} color="primary" autoFocus>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </MaterialGrid>
    );
  }
}

ShareToParticipants.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loadingSegmentedShare: PropTypes.any,
    segmentedShare: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    alternativeSummary: PropTypes.any,
    isThereAlternativeSubject: PropTypes.any,
    isThereDescription: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getSegmentedUsers: PropTypes.func,
  handleSubmit: PropTypes.func,
  sendMailParticipants: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('ShareToParticipantsMailForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDetail,
      sendTestMail,
      getSegmentedUsers,
      sendMailParticipants,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'ShareToParticipantsMailForm',
  })(ShareToParticipants)
);
