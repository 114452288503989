import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';

class Interaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmation: false,
    };
  }

  onSubmit = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  render() {
    const atividade = this?.props?.formValues;
    const now = new Date();
    const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);
    return this.props.memberGetMember.loading ? (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    ) : (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 15,
            }}>
            <Typography variant="subtitle2">
              Para que os usuários consigam gerar convites, você precisa definir um prefixo para
              identificação da atividade.
            </Typography>
            <MaterialGrid container direction="column">
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label={'Digite um prefixo para código'}
                  disabled={
                    this.props.disabled ||
                    this.props.router.params.action === 'proposta' ||
                    isDisabled
                  }
                  name="code_mgm"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              maxWidthFixed
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              general={this.props.general}
              title={this.props.confirmationTitle}
              text={this.props.confirmationSubtitle}
              confirmButtonColor={'primary'}
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={() => this.props.router.push('/owner/acoes/minhas-atividades')}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
            }}>
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading || isDisabled}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Interaction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  disabled: PropTypes.any,
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  memberGetMember: PropTypes.shape({
    loading: PropTypes.any,
  }),
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
    push: PropTypes.func,
  }),
  setOpenConfirmation: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    memberGetMember: state.memberGetMember,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('mgmForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'mgmForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Interaction)
);
