import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Answers from 'common/components/quiz/Answers';
import Question from 'common/components/quiz/Questions';
import { getList as getCategoriesList } from 'modules/owner/pages/categories/categoriesActions';
import React from 'react';
import { ExpansionList } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';
import { AnswersContainer, Container } from './styles';

let CheckListQuiz = function (props) {
  const onSubmit = (values) => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    props.onClickProgress(values);
  };
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);

  const renderQuestions = (props) => {
    return (
      <ul style={{ listStyleType: 'none' }}>
        {props.fields.map((question, index) => (
          <li key={index}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12} sm={12}>
                <Field
                  component={Question}
                  label={`Pergunta ${index + 1}`}
                  name={`${question}.question`}
                  // validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                  disabled={isDisabled}
                />
                {props.meta.submitFailed && props.meta.error && (
                  <Typography color="error" variant="caption">
                    {props.meta.error}
                  </Typography>
                )}
              </GeneralGrid>
              <GeneralGrid item xs={12} sm={12}>
                <FieldArray name={`${question}.options`} component={renderOptions} />
              </GeneralGrid>
            </GeneralGrid>
          </li>
        ))}
      </ul>
    );
  };

  const renderOptions = (props) => {
    return (
      <ul style={{ listStyleType: 'none', marginTop: 20 }}>
        <li>
          <Box>
            <PrimaryButton onClick={() => (isDisabled ? null : props.fields.push())}>
              Adicionar opção
            </PrimaryButton>
          </Box>
        </li>
        <AnswersContainer>
          {props.fields.map((option, index) => (
            <li key={index}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Field component={Answers} name={`${option}.label`} />
                <FontAwesomeIcon
                  style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                  icon={faTrash}
                  onClick={() => (isDisabled ? null : props.fields.remove(index))}
                />
              </Box>
            </li>
          ))}
        </AnswersContainer>
      </ul>
    );
  };

  return (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          <Container>
            <ExpansionList>
              <FieldArray name="questions" component={renderQuestions} />
            </ExpansionList>
          </Container>
          <GenericConfirmationCard
            open={props.openConfirmation}
            cancelClose={props.handleCancelClose}
            maxWidthFixed
            labelLeftButton={'Continuar aqui'}
            labelRightButton={props.buttonConfirmationLabel}
            general={props.general}
            confirmButtonColor={'primary'}
            title={props.confirmationTitle}
            text={props.confirmationSubtitle}
            onClose={() => props.setOpenConfirmation(false)}
            onClick={props.closeModal}
          />
        </div>
        <GeneralGrid
          container
          display="flex"
          justify="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            disabled={props.activeActions.buttonLoading || isDisabled}
            color="primary"
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}>
            Salvar e continuar
          </PrimaryButton>
        </GeneralGrid>
      </Form>
    </>
  );
};

CheckListQuiz.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
  }),
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.any,
    submitFailed: PropTypes.any,
  }),
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  setOpenConfirmation: PropTypes.func,
};

CheckListQuiz = reduxForm({
  form: 'checkListForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CheckListQuiz);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    surveysCheckList: state.surveysCheckList,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('checkListForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckListQuiz);
