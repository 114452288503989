import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { InputEasyImageWithProcessing } from 'common/components/fields/InputEasyImageWithProcessing';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import UploadVideos from 'common/components/upload';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { requiredFieldError } from 'modules/owner/pages/actions/actionsFile';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormMeta, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import NewSelectMultiple from 'common/components/fields/NewSelectMultiple';
import { getList as getCategoriesList } from '../../categories/categoriesActions';
import { getGroupingsStationId } from '../groupings/groupingsActions';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      poppedUp: false,
    };
    this.props.getCategoriesList(this.props.auth.user.establishments[0].stations[0].id);
    this.props.getGroupingsStationId(this.props.auth.user.establishments[0].stations[0].id);
  }

  onSubmit = (values) => {
    if (values.categories_field) {
      values.categories = values.categories_field.map((item) => item.id);
    }
    if (values.groupings_field) {
      values.groupings = values.groupings_field.map((item) => item.id);
    }
    values.submitFromButton = true;
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  componentDidUpdate(props) {
    if (!_.isEmpty(this.props.submitErrors) && this.state.submitted && !this.state.poppedUp) {
      this.props.requiredFieldError();
      this.setState({ poppedUp: true });
      this.setState({ submitted: false });
    }
    if (this.props.submitErrors !== props.submitErrors && this.state.poppedUp) {
      this.setState({ poppedUp: false });
    }
  }

  render() {
    const categoriesList = this.props.categories.list.content.map((item, index) => ({
      id: item.id,
      title: item.name,
    }));
    const groupingsList = this.props.groupings.map((item, _) => ({
      id: item.id,
      title: item.name,
    }));
    const atividade = this?.props?.formValues;
    const now = new Date();
    const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);

    return (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              padding: '0 10px',
            }}>
            <div style={{ marginTop: isMobile ? 0 : 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                Categoria
              </Typography>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12}>
                  <Field
                    component={NewSelectMultiple}
                    label={'Categorias'}
                    options={categoriesList}
                    name="categories_field"
                    margin="normal"
                    disabled={isDisabled}
                  />
                </MaterialGrid>
              </MaterialGrid>
            </div>
            <div style={{ marginTop: 20 }}>
              <MaterialGrid item xs={12}>
                <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                  Título
                </Typography>
                <Field
                  component={InputLabelOutlined}
                  label="Nome dessa atividade"
                  name="name"
                  validate={[FORM_RULES.required, FORM_RULES.maxL(60)]}
                  required
                  margin="normal"
                  maxLength={60}
                  disabled={isDisabled}
                />
              </MaterialGrid>
            </div>
            <div style={{ marginTop: isMobile ? 0 : 20 }}>
              <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                Agrupamento
              </Typography>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12}>
                  <Field
                    component={NewSelectMultiple}
                    label={'Grupos'}
                    options={groupingsList}
                    name="groupings_field"
                    margin="normal"
                    disabled={isDisabled}
                  />
                </MaterialGrid>
              </MaterialGrid>
            </div>
            {/* <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Resumo da atividade para o participante"
              name="summary"
              disabled={this.props.disabled}
              validate={[FORM_RULES.required]}
              required
              margin="normal"
            />
          </MaterialGrid> */}
            {/* Se não for redirect interno */}
            {parseInt(this.props.formValues?.type_id) !== 20 ? (
              <div style={{ marginTop: 20 }}>
                <MaterialGrid item xs={12}>
                  <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                    Texto do botão
                  </Typography>
                  <Field
                    component={InputLabelOutlined}
                    label="Label do card (Ex: Participe)"
                    name="click_me_label"
                    required
                    margin="normal"
                    disabled={isDisabled}
                  />
                </MaterialGrid>
              </div>
            ) : null}
            {/* </MaterialGrid> */}
            {!isDisabled && (
              <div style={{ marginTop: 20 }}>
                <Typography variant="subtitle1" align="start">
                  {parseInt(this.props.formValues?.type_id) === 18
                    ? 'Capa da atividade'
                    : 'Imagens'}
                </Typography>

                <MaterialGrid item xs={12}>
                  <Field
                    component={InputEasyImageWithProcessing}
                    label={'Imagem .png ou .jpg'}
                    cols="12 12 12 12"
                    name="image"
                    aspect={16 / 9}
                    aspectLabel={'16:9'}
                    validate={[FORM_RULES.required]}
                  />
                </MaterialGrid>
              </div>
            )}

            {parseInt(this.props.formValues?.type_id) !== 18 ? (
              <div style={{ marginTop: 25 }}>
                <MaterialGrid item xs={12}>
                  <Field
                    component={EditorConvertToHTMLActivity}
                    label={'Conteúdo da postagem'}
                    name="description"
                    imageRatio={'none'}
                    disabled={isDisabled}
                  />
                </MaterialGrid>
              </div>
            ) : null}

            {this.props.formValues && parseInt(this.props.formValues.type_id) !== 18 ? (
              <MaterialGrid item xs={12} style={{ margin: '25px 0px' }}>
                <Field
                  component={UploadVideos}
                  indexVideo={0}
                  multipleVideos={false}
                  formValues={this.props.formValues ? this.props.formValues : null}
                  name={`videoIdField`}
                  disabled={isDisabled}
                />
              </MaterialGrid>
            ) : null}
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              maxWidthFixed
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              general={this.props.general}
              confirmButtonColor={'primary'}
              title={this.props.confirmationTitle}
              text={this.props.confirmationSubtitle}
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
              textTransform: 'none',
            }}>
            <PrimaryButton
              type="submit"
              color="secondary"
              disabled={true}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              onClick={() => this.setState({ submitted: true })}
              disabled={this.props.activeActions.buttonLoading || isDisabled}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '11px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Content.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  buttonConfirmationLabel: PropTypes.any,
  categories: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
  }),
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  formValues: PropTypes.shape({
    type_id: PropTypes.any,
  }),
  general: PropTypes.any,
  getCategoriesList: PropTypes.func,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  requiredFieldError: PropTypes.func,
  setOpenConfirmation: PropTypes.func,
  submitErrors: PropTypes.any,
};

const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    categories: state.categories,
    groupings: state.groupings.list,
    general: state.general,
    formValues: getFormValues(form)(state),
    submitErrors: getFormSyncErrors(form)(state),
    metaForm: getFormMeta(form)(state),
    form: form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoriesList,
      getGroupingsStationId,
      requiredFieldError,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Content)
);
