import PropTypes from 'prop-types';
import { FormControl, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
// import { Autocomplete } from 'react-md';
import * as React from 'react';
import { appColors } from 'styles/colors';

const useStylesSelectLabel = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
  inputLabel: {
    color: appColors.primary,
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function NewSelectMultiple({
  label,
  input,
  meta: { touched, error },
  options,
  disabled,
}) {
  const classes = useStylesSelectLabel();
  const [option, setOption] = React.useState([]);
  React.useEffect(() => {
    if (input.value.length >= 0) {
      setOption(input.value || []);
    }
  }, [input.value]);
  function handleChange(event, value) {
    input.onChange(value);
  }
  return (
    <div className={classes.root}>
      <FormControl
        aria-label="Tooltip do Formulário"
        variant="outlined"
        color="primary"
        className={classes.formControl}>
        <Autocomplete
          id="tags-outlined"
          multiple
          filterSelectedOptions
          label={label}
          options={options}
          value={option}
          placeholder={label}
          getOptionLabel={(option) => option?.title}
          onChange={handleChange}
          disableClearable
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
          disabled={disabled}
        />
        {touched && error ? (
          <FormHelperText style={{ color: '#FF5A52' }}>{touched && error}</FormHelperText>
        ) : (
          <FormHelperText>Você pode selecionar mais de uma opção</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

NewSelectMultiple.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  options: PropTypes.any,
};
