import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardFooter from 'material-dashboard-components/Card/CardFooter.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import { getActionResults } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { ColumnsBelowSearch, NumberGenericElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import { getProductAction } from 'modules/owner/pages/sales/productOrders/productOrdersActions.js';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
const { format } = require('date-fns');

class Results extends Component {
  constructor(props) {
    super(props);
    this.props.getActionResults(this.props.location.query.action);
    this.props.getProductAction(this.props.location.query.action);
  }

  onDialogClose = () => {
    if (this.props.location.query.from === 'inicio') {
      this.props.router.push('/owner/acoes/inicio');
    } else if (this.props.location.query.from === 'contacts') {
      this.props.router.push('/owner/contacts');
    } else {
      this.props.router.push(
        `/owner/acoes/minhas-atividades/?to=${this.props.location.query.from}`
      );
    }
  };

  converterParaCSV = (listaDeInformacao) => {
    let j = 0;
    let contador = 0;
    if (!Array.isArray(listaDeInformacao) || listaDeInformacao.length === 0) {
      console.error('Lista de objetos vazia ou inválida.');
      return '';
    }
    // Obtém os cabeçalhos do CSV a partir das chaves selecionadas
    const cabe = [
      'Nome',
      'CPF',
      'Telefone',
      'E-mail',
      'Código',
      'Produto',
      'Quantidade',
      'Valor',
      'Data da compra',
      'Status',
    ];
    // Gera as linhas do CSV
    const linhas = [];
    for (let i = 0; i < listaDeInformacao.length; i++) {
      const product = listaDeInformacao[i];
      for (let a = 0; a < product.single_product_orders.length; a++) {
        const spo = product.single_product_orders[a];
        const updatedat = new Date(product.updatedAt);
        const produto = spo.product.name;
        const userName = product.user.name;
        linhas[`${j}`] = [];
        linhas[j].push(userName.replace(/,/g, '.'));
        linhas[j].push(product.user.cpf);
        linhas[j].push(product.user.tel);
        linhas[j].push(product.user.email);
        linhas[j].push(product.code);
        linhas[j].push(produto.replace(/,/g, '.'));
        linhas[j].push(1);
        linhas[j].push(spo.product.value);
        linhas[j].push(format(updatedat, 'dd/MM/yyyy HH:mm:ss'));
        linhas[j].push(product.status);
        j++;
      }
    }
    linhas[`${j}`] = [];
    const linhasCSV = linhas.map((linha) => linha.join(','));
    const csv = [cabe.join(','), ...linhasCSV].join('\n');
    return csv;
  };

  gerarExcel = (csvString, titulo, actionName = '') => {
    try {
      // Parse da string CSV para um array de arrays
      const nomeAtividade =
        titulo[0]?.presential_course_subscriptions[0]?.presential_course?.action?.name ||
        actionName;
      const dataAtual = new Date();
      const dia = dataAtual.getDate();
      const mes = dataAtual.getMonth() + 1; // Adicione 1 porque os meses começam do zero
      const ano = dataAtual.getFullYear();
      const csvData = csvString.split('\n').map((row) => row.split(','));
      // Crie um workbook do Excel
      const workbook = XLSX.utils.book_new();
      // Crie uma planilha a partir dos dados CSV
      const worksheet = XLSX.utils.aoa_to_sheet(csvData);
      // Adicione a planilha ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // Salve o workbook como um arquivo Excel
      XLSX.writeFile(workbook, `relatorio_${nomeAtividade}_${dia}_${mes}_${ano}.xlsx`);
    } catch (error) {
      toastr.error('Erro', 'Não foi possível exportar o relatório.');
      console.error('Não foi possível gerar o relatório.', error);
    }
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Resultados"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}>
        <section id="statement-buy-points" style={{ height: '100vh' }}>
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <h2 className="signup-text">Ninguém participou dessa atividade até o momento</h2>
                  <p>Aqui você poderá distribuir os prêmios</p>
                </GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img alt="" src={placeholder_illustration} className="img-fluid" />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  render() {
    const { classes } = this.props;
    const { actionResults, loadingActionResults, actionDetail } = this.props.activeActions;
    const { impressions, reach, participations, cashback, cashback_left, usersReached } =
      actionResults;
    const CSVprint = this.converterParaCSV(this.props.productOrders.loading.data);
    if (loadingActionResults) {
      return (
        <AlertDialogSlide
          title="Resultados"
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    return (
      <AlertDialogSlide
        isFullScreen={isMobile}
        title={`${actionDetail.name} (${moment(actionDetail.start).format('DD/MM')} - ${
          moment(actionDetail.end).isAfter(new Date())
            ? 'Hoje'
            : moment(actionDetail.end).format('DD/MM')
        }) `}
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'md'}>
        <Typography variant="h6">Resultados gerais</Typography>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">remove_red_eye</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Impressões</p>
                <h3 className={classes.cardTitle}>{impressions}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de visualizações que sua atividade teve, independente se for do mesmo usuário ou de usuários diferentes."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">people_alt</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Alcance</p>
                <h3 className={classes.cardTitle}>{reach}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participantes que viram sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">call_made</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Participações</p>
                <h3 className={classes.cardTitle}>{participations.length}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participações efetivas na sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">bar_chart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Conversão</p>
                <h3 className={classes.cardTitle}>
                  {participations.length > 0
                    ? parseFloat((participations.length / reach) * 100).toFixed(1)
                    : 0}
                  %
                </h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a porcentagem de pessoas que participaram dessa atividade em relação a todas que foram alcançadas"
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Gasto atual</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é quantidade de dinheiro que já foi paga em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Cashback restante</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback_left).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é a quantidade de dinheiro que foi reservada para ação mas ainda não foi distribuida em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            {!_.isEmpty(usersReached) && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <button
                    onClick={() => this.gerarExcel(CSVprint, usersReached, actionDetail.name)}
                    aria-label="Gerar Excel">
                    <FontAwesomeIcon
                      icon={faBook}
                      style={{ color: 'green', fontSize: '25px', marginLeft: '832px' }}
                    />
                  </button>
                </div>
              </div>
            )}
            <Table
              hasToolBar={false}
              buttonCSV
              csvData={CSVprint}
              defaultSelected={''}
              title="Extrato"
              columns={[
                { field: 'name', title: 'Nome' },
                {
                  field: 'quantity',
                  title: 'Visualizações',
                  render: (rowData) => (
                    <NumberGenericElement category="visualizations" value={rowData.quantity} />
                  ),
                },
                {
                  title: 'Participação',
                  render: (rowData) =>
                    rowData.user_actions.length > 0 ? 'Visualizou e pagou' : 'Visualizou',
                },
                {
                  title: 'Email',
                  field: 'email',
                },
                {
                  title: 'Search',
                  field: 'search',
                  render: (rowData) => (
                    <ColumnsBelowSearch
                      buttons={{
                        namedButtons: [
                          {
                            name: rowData.user_actions.length > 0 ? '-' : 'Ainda não participou',
                            onClick: () => {},
                            disabled: true,
                          },
                        ],
                        iconButtons: [],
                      }}
                    />
                  ),
                },
              ]}
              rows={usersReached}
              onChangeOption={() => {}}
              selectOptions={[]}
            />
          </GeneralGrid>
        </GeneralGrid>
      </AlertDialogSlide>
    );
  }
}

Results.propTypes = {
  getProductAction: PropTypes.func.isRequired,
  activeActions: PropTypes.shape({
    actionResults: PropTypes.shape({
      cashback: PropTypes.any,
      cashback_left: PropTypes.any,
      impressions: PropTypes.any,
      participations: PropTypes.shape({
        length: PropTypes.number,
      }),
      reach: PropTypes.any,
      usersReached: PropTypes.any,
    }),
    loadingActionResults: PropTypes.any,
    actionDetail: PropTypes.shape({
      end: PropTypes.any,
      name: PropTypes.any,
      start: PropTypes.any,
    }),
  }),
  classes: PropTypes.shape({
    cardCategory: PropTypes.any,
    cardTitle: PropTypes.any,
    stats: PropTypes.any,
  }),
  getActionResults: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.any,
      from: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    productOrders: state.productOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionResults,
      getProductAction,
    },
    dispatch
  );
};

const StyledResults = withStyles(styles)(Results);

export default connect(mapStateToProps, mapDispatchToProps)(StyledResults);
