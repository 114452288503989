import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { NewActivity } from 'common/components/email';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputSwitchGrey } from 'common/components/fields/InputSwitchGrey';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import {
  getActionDetail,
  sendTestMail,
} from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';

class ShareTest extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  onSubmit = (values) => {
    if (values.sendToUserMail) {
      values.email = this.props.auth.user.email;
    }
    values.action_id = this.props.action.id;
    values.station_id = this.props.auth.user.establishments[0].stations[0].id;
    values.user_id = this.props.auth.user.id;
    values.alternative_summary = values.alternativeSummary;
    values.alternative_subject = values.alternativeSubject;
    values.image = this.props.action.image;
    this.props.sendTestMail(values);
  };

  render() {
    const { handleSubmit, formValues, disabled, general } = this.props;
    const isButtonLoading = this.props.activeActions.buttonLoading;
    const hasDescription = formValues && formValues.isThereDescription;
    const hasSubject = formValues && formValues.isThereAlternativeSubject;
    const action = this.props.action;
    const station = this.props.auth.user.establishments[0].stations[0];
    const establishment = this.props.auth.user.establishments[0];
    const alternativeSummay = this.props.formValues && this.props.formValues.alternativeSummary;
    const userEmail = this.props.auth.user.email;

    return (
      <MaterialGrid container spacing={8}>
        <MaterialGrid item xs={6} style={{ borderRight: '1px solid lightgray' }}>
          <Typography style={{ textAlign: 'left', fontSize: 12, color: 'gray' }}>
            Pré-visualização
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: NewActivity(action, station, establishment, alternativeSummay),
            }}></div>
        </MaterialGrid>
        <MaterialGrid item xs={6}>
          <MaterialGrid item xs={12}>
            <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputSwitch}
                  label={`Enviar para ${userEmail}`}
                  name="sendToUserMail"
                  required
                  margin="normal"
                />
              </MaterialGrid>

              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="E-mail para teste"
                  name="email"
                  validate={
                    formValues
                      ? formValues.sendToUserMail
                        ? null
                        : [FORM_RULES.required, FORM_RULES.email]
                      : [FORM_RULES.required, FORM_RULES.email]
                  }
                  required
                  margin="normal"
                  disabled={
                    formValues
                      ? formValues.sendToUserMail
                        ? formValues.sendToUserMail
                        : false
                      : false
                  }
                />
              </MaterialGrid>
              <MaterialGrid
                item
                xs={12}
                style={{
                  marginTop: 16,
                }}>
                <Field
                  component={InputSwitchGrey}
                  label={'Deseja adicionar sua própria descrição da atividade?'}
                  boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                  firstState="Ativado"
                  secondState="Selecione para escrever sua própria descrição para a atividade"
                  disabled={disabled}
                  name="isThereDescription"
                  margin="normal"
                />
              </MaterialGrid>
              {hasDescription && (
                <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                  <Field
                    component={EditorConvertToHTMLActivity}
                    label={'Descrição alternativa'}
                    name="alternativeSummary"
                    border
                  />
                </MaterialGrid>
              )}
              <MaterialGrid
                item
                xs={12}
                style={{
                  marginTop: 16,
                }}>
                <Field
                  component={InputSwitchGrey}
                  label={'Deseja adicionar seu próprio assunto do e-mail?'}
                  boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                  firstState="Ativado"
                  secondState="Selecione para escrever seu próprio assunto do e-mail. O padrão é: 'Olá, temos uma nova atividade para você'"
                  disabled={disabled}
                  name="isThereAlternativeSubject"
                  margin="normal"
                />
              </MaterialGrid>
              {hasSubject && (
                <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                  <Field
                    component={InputLabelOutlined}
                    label={'Assunto alternativo'}
                    name="alternativeSubject"
                  />
                </MaterialGrid>
              )}
              <MaterialGrid container justifyContent="flex-end">
                <PrimaryButton
                  type="submit"
                  color="primary"
                  disabled={isButtonLoading}
                  progress={parseInt(general.submitProgress)}>
                  Enviar Teste
                </PrimaryButton>
              </MaterialGrid>
            </Form>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>
    );
  }
}

ShareTest.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.any,
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    alternativeSummary: PropTypes.any,
    isThereAlternativeSubject: PropTypes.any,
    isThereDescription: PropTypes.any,
    sendToUserMail: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  sendTestMail: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('shareTestMailForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDetail,
      sendTestMail,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'shareTestMailForm',
  })(ShareTest)
);
