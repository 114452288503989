import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import MaterialCard from 'common/components/cards/materialCard';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { SelectButton } from 'primereact/selectbutton';
import 'primereact/resources/themes/nova/theme.css';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.css';
import MaterialGrid from '@material-ui/core/Grid';
import { ActionNameElement } from 'common/components/table/tableComponents';
import 'common/components/table/tableGeneral.css';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Engagement from '../../../../../assets/titlesMobile/engagement.svg';
import { BASE_S3 } from '../../../../../config/consts';
import {
  deleteActionDraft,
  getLazyStationActionsDrafts,
  onClickDraftAction,
  getTotalNumberActionsDrafts,
} from '../actionsDrafts/actionsDraftsScripts';
import { getTemplates } from '../actionsFile';
import {
  deleteActionSuspended,
  getLazyStationHistory,
  reactivateAction,
  getTotalArchivedActions,
} from '../actionsHistory/actionsHistoryActions';
import ReactivateAction from '../actionsHistory/reactivateForm';
import {
  duplicateAction,
  getLazyStationActiveActions,
  getLazyStationScheduledActions,
  onClickAction,
  onClickActionResults,
  onClickActionShare,
  suspendActivity,
  getTotalNumberActiveActions,
  getTotalNumberScheduledActions,
} from '../activeActions/activeActionsScripts';
import { VouchersEmLoteModal } from 'modules/owner/pages/vouchers';
import {
  FileCopy,
  BarChart,
  Edit,
  Delete,
  Send,
  Queue,
  Stop,
  Replay,
  ToggleOn,
} from '@material-ui/icons';
import { Eye } from 'react-feather';

function GeneralActions(props) {
  const { auth } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState({});
  const [item, setItem] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [itemHistory] = React.useState(null);
  const [finishDelete, setFinishDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDuplicateBackDrop, setOpenDuplicateBackDrop] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openModalLote, setOpenModalLote] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [reactiveRow, setReactiveRow] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visible, setVisible] = useState(false);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '', matchMode: 'contains' },
    },
  });

  const options = [
    { label: 'Online', value: 'online' },
    { label: 'Programadas', value: 'scheduled' },
    { label: 'Rascunho', value: 'drafts' },
    { label: 'Encerradas', value: 'closed' },
  ];

  useEffect(() => {
    props.getTemplates(auth.user.establishments[0].id);
    setSelectedOption('online');
  }, []);

  useEffect(() => {
    if (selectedOption === 'scheduled') {
      setTotalRecords(props.actionsTotal?.total ?? 700);
      setData(props.activeActions.list);
    }
    if (selectedOption === 'drafts') {
      setTotalRecords(props.actionsTotal?.total ?? 700);
      setData(props.actionsDrafts.list);
    }
    if (selectedOption === 'online') {
      setTotalRecords(props.actionsTotal?.total ?? 700);
      setData(props.activeActions.list);
    }
    if (selectedOption === 'closed') {
      setTotalRecords(props.actionsTotal?.total ?? 700);
      setData(props.actionsHistory.list);
    }
    setLoading(false);
  }, [props.activeActions.list, props.actionsDrafts.list, props.actionsHistory.list]);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }
    lazyState.first = 0;
    lazyState.page = 0;
    lazyState.rows = 10;
    setLoading(true);
    if (selectedOption === 'drafts') {
      props.getTotalNumberActionsDrafts(auth.user.establishments[0].stations[0].id, lazyState);
      props.getLazyStationActionsDrafts(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'online') {
      props.getTotalNumberActiveActions(auth.user.establishments[0].stations[0].id, lazyState);
      props.getLazyStationActiveActions(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'scheduled') {
      props.getTotalNumberScheduledActions(auth.user.establishments[0].stations[0].id, lazyState);
      props.getLazyStationScheduledActions(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'closed') {
      props.getTotalArchivedActions(auth.user.establishments[0].stations[0].id, lazyState);
      props.getLazyStationHistory(auth.user.establishments[0].stations[0].id, lazyState);
    }
  }, [selectedOption]);

  useEffect(() => {
    setLoading(true);
    if (selectedOption === 'drafts') {
      props.getLazyStationActionsDrafts(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'online') {
      props.getLazyStationActiveActions(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'scheduled') {
      props.getLazyStationScheduledActions(auth.user.establishments[0].stations[0].id, lazyState);
    }
    if (selectedOption === 'closed') {
      props.getLazyStationHistory(auth.user.establishments[0].stations[0].id, lazyState);
    }
  }, [lazyState]);

  function loadData(option) {
    setSelectedOption(option);
  }

  function handleClose() {
    setVisible(false);
  }

  const useStyles = makeStyles({
    root: {
      display: 'contents',
      '& .p-datatable-tbody': {
        display: 'none',
      },
      '& .p-column-header-content': {
        display: 'none',
      },
    },
  });

  const classes = useStyles();

  const renderTemplatesList = () => {
    if (props.actions.templatesLoading) {
      return (
        <Box display="flex" width="100vw" justifyContent="center" alignContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    const templatesBase = props.actions?.templatesList?.filter(
      (item) => parseInt(item.category) === 1
    );

    if (templatesBase.length > 0) {
      return templatesBase.map((item, index) => {
        return (
          <GeneralGrid item xs={12} md={3} key={index} style={{ marginBottom: 10 }}>
            <MaterialCard
              horizontalCard
              onClick={
                item.has_bought
                  ? () => {
                      setOpen(true);
                      setTemplate(item);
                    }
                  : () => props.router.push(`/owner/acoes/${item.id}`)
              }
              name={item.name}
              has_bought={item.has_bought}
              image={`${BASE_S3}/${item.owner_image || item.image}`}
              buttonLabel={item.has_bought ? 'Ativar' : 'Veja Mais'}
              type={item.type.name}
            />
          </GeneralGrid>
        );
      });
    } else {
      return null;
    }
  };

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Atividades Aprovadas</h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton onClick={() => props.router.push('/owner/acoes')} color="primary">
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (
    !props.activeActions.loading &&
    !props.activeActions.loading &&
    !props.activeActions.loading &&
    props.actions.templatesList.length < 1 &&
    !props.actions.templatesLoading
  ) {
    return renderEmptyScreen();
  }

  const renderEmptyCard = () => {
    return (
      <MaterialGrid item xs={12} md={3} style={{ marginBottom: 10 }}>
        <MaterialCard
          horizontalCard
          router={props.router}
          cardEmpty
          onClick={() => {
            props.router.push('/owner/acoes');
          }}
          name={'Galeria de Atividades'}
          image={``}
          type={''}
        />
      </MaterialGrid>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div
        style={{
          paddingRight: '10px',
          paddingLeft: '10px',
        }}>
        {option.label}
      </div>
    );
  };

  const dateBodyTemplateStart = (rowData) => {
    return <span>{rowData.startDate}</span>;
  };
  const dateBodyTemplateAnd = (rowData) => {
    return <span>{rowData.endDate}</span>;
  };

  const categoriesTemplate = (rowData) => {
    const categoryNamesString =
      rowData?.category_actions
        ?.map((categoryAction) => categoryAction.category?.name)
        .join(`, `) || `Sem categoria`;
    return <span>{categoryNamesString}</span>;
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <ActionNameElement
        className="p-column-filter"
        singleColum={false}
        actionName={rowData['name']}
        image={rowData['image']}
      />
    );
  };

  const botaoAcaoEditar = (rowData) => {
    return (
      <Tooltip title="Editar">
        <IconButton
          aria-label="Editar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickAction(rowData, props.router, 'online')}>
          <Edit />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoDuplicar = (rowData) => {
    return (
      <Tooltip title="Duplicar">
        <IconButton
          aria-label="Duplicar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.duplicateAction(rowData, props.router, setOpenDuplicateBackDrop)}>
          <FileCopy />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoVerResultados = (rowData) => {
    return (
      <Tooltip title="Ver resultados">
        <IconButton
          aria-label="Ver resultados"
          style={{ backgroundColor: 'transparent' }}
          onClick={() =>
            props.onClickActionResults(rowData, props.router, 'online') &
            sessionStorage.removeItem('currentPage')
          }>
          <BarChart />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoDeletar = (rowData) => {
    return (
      <Tooltip title="Deletar">
        <IconButton
          aria-label="Deletar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            setOpenConfirmation(true);
            setItem(rowData.id);
          }}>
          <Delete />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoReativar = (rowData) => {
    return (
      <Tooltip title="Reativar">
        <IconButton
          aria-label="Reativar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            setVisible(true);
            setReactiveRow(rowData);
          }}>
          <Replay />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoEncerrar = (rowData) => {
    return (
      <Tooltip title="Encerrar">
        <IconButton
          aria-label="Encerrar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.suspendActivity(rowData.id, props.router, true)}>
          <Stop />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAcaoDivulgar = (rowData) => {
    return (
      <Tooltip title="Divulgar">
        <IconButton
          aria-label="Divulgar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickActionShare(rowData, props.router)}>
          <Send />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoGerarVouchers = (rowData) => {
    return (
      <Tooltip title="Gerar Vouchers em Lote">
        <IconButton
          aria-label="Gerar Vouchers em Lote"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            setSelectedRow(rowData);
            setOpenModalLote(true);
          }}>
          <Queue />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoAtivarAgora = (rowData) => {
    return (
      <Tooltip title="Ativar agora">
        <IconButton
          aria-label="Ativar agora"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickAction(rowData, props.router, 'scheduled')}>
          <ToggleOn />
        </IconButton>
      </Tooltip>
    );
  };

  const botaoVerAtividadeEncerrada = (rowData) => {
    return (
      <Tooltip title="Visualizar">
        <IconButton
          aria-label="Visualizar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickAction(rowData, props.router, 'closed')}>
          <Eye />
        </IconButton>
      </Tooltip>
    );
  };

  const acoesBodyTemplate = (rowData) => {
    if (selectedOption === 'online') {
      return (
        <div>
          {botaoAcaoEditar(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {botaoAcaoVerResultados(rowData)}
          {botaoAcaoEncerrar(rowData)}
          {botaoAcaoDivulgar(rowData)}
          {botaoGerarVouchers(rowData)}
        </div>
      );
    }
    if (selectedOption === 'drafts') {
      return (
        <div>
          {botaoAcaoEditarRascunho(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {botaoAcaoDeletar(rowData)}
        </div>
      );
    }
    if (selectedOption === 'scheduled') {
      return (
        <div>
          {botaoAcaoEditar(rowData)}
          {botaoAcaoEncerrar(rowData)}
          {botaoAtivarAgora(rowData)}
        </div>
      );
    }
    if (selectedOption === 'closed') {
      return (
        <div>
          {botaoVerAtividadeEncerrada(rowData)}
          {botaoAcaoDuplicar(rowData)}
          {botaoAcaoVerResultados(rowData)}
          {botaoAcaoDeletar(rowData)}
          {botaoAcaoReativar(rowData)}
        </div>
      );
    }
  };

  const botaoAcaoEditarRascunho = (rowData) => {
    return (
      <Tooltip title="Editar">
        <IconButton
          aria-label="Editar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => props.onClickDraftAction(rowData, props.router)}>
          <Edit />
        </IconButton>
      </Tooltip>
    );
  };

  const onFilter = (event) => {
    event['first'] = 0;
    setlazyState(event);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setlazyState((prevState) => ({
      ...prevState,
      first: newPage * rowsPerPage,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setlazyState((prevState) => ({
      ...prevState,
      first: 0,
      rows: newRowsPerPage,
    }));
  };

  const onPage = (event) => {
    setlazyState(event);
  };

  return (
    <MaterialGrid
      container
      justifyContent="center"
      xs={12}
      style={{
        maxWidth: '86vw',
        paddingBottom: '4rem',
      }}>
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.actionsDrafts.buttonLoading}
        cancelClose={() => setOpenConfirmation(false)}
        general={props.general}
        title={`${item ? 'Deletar rascunho' : 'Deletar atividade'}`}
        confirmButtonColor={'primary'}
        text={`${
          item
            ? `Tem certeza que deseja deletar esse rascunho`
            : `Tem certeza que deseja deletar essa atividade`
        }? Essa ação não poderá ser desfeita`}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) props.deleteActionDraft(item, setFinishDelete, setOpenConfirmation);
          if (itemHistory) {
            props.deleteActionSuspended(itemHistory, setFinishDelete, setOpenConfirmation);
          }
        }}
      />
      <GeneralGrid
        container
        justifyContent="center"
        spacing={2}
        style={{
          display: 'flex',
          marginBottom: 15,
          marginTop: 15,
        }}>
        {isMobile ? (
          <div className="d-flex justify-content-start align-items-center container-fluid">
            <div style={{ width: 23, height: 23 }}>
              <img src={Engagement} alt="engagement" style={{ width: 23, height: 'auto' }} />
            </div>
            <h4
              style={{
                fontSize: 20,
                fontWeight: 400,
                margin: 0,
                marginLeft: 15,
              }}>
              Engajamento
            </h4>
          </div>
        ) : null}
      </GeneralGrid>
      <GeneralGrid
        container
        spacing={2}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          overflowY: 'hidden',
          marginBottom: '.7rem',
        }}>
        {isMobile ? null : renderEmptyCard()}
        {renderTemplatesList()}
      </GeneralGrid>

      <MaterialGrid
        container
        style={{
          marginTop: isMobile ? 0 : 15,
          marginBottom: isMobile ? 0 : 15,
        }}>
        <div>
          <SelectButton
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.value)}
            optionLabel="label"
            options={options}
            allowEmpty="false"
            style={{
              width: '100%',
              marginBottom: 10,
              color: 'black',
              backgroundColor: 'white',
              background: 'white',
              outlineColor: 'white',
              borderBlockEndColor: 'white',
            }}
            itemTemplate={itemTemplate}
          />
        </div>
        <div className={classes.root}>
          <DataTable
            value={data}
            lazy
            totalRecords={totalRecords}
            rows={lazyState.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            scrollable
            loading={loading}
            first={lazyState.first}
            onFilter={onFilter}
            onPage={onPage}
            resizableColumns
            filterDisplay="row"
            filters={lazyState.filters}
            style={{ width: '100%' }}>
            <Column
              field="name"
              header="Nome"
              sortable
              filter
              filterPlaceholder="Pesquisar"
              style={{ minWidth: '14rem', minHeight: '45rem' }}></Column>
          </DataTable>
        </div>
      </MaterialGrid>
      <>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Início da Publicação</TableCell>
                <TableCell>Fim da Publicação</TableCell>
                <TableCell>Categorias</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{nameBodyTemplate(row)}</TableCell>
                  <TableCell>{dateBodyTemplateStart(row)}</TableCell>
                  <TableCell>{dateBodyTemplateAnd(row)}</TableCell>
                  <TableCell>{categoriesTemplate(row)}</TableCell>
                  <TableCell>{acoesBodyTemplate(row)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </TableContainer>
      </>
      <ReactivateAction
        visible={visible}
        onClose={handleClose}
        router={props.router}
        actionData={reactiveRow}
      />
      <ConfirmationCard
        onClose={() => setOpen(false)}
        open={open}
        item={template.id}
        station_id={props.auth.user.establishments[0].stations[0].id}
        partner_id={null}
        sponsored={null}
        router={props.router}
      />
      <VouchersEmLoteModal
        atividade={selectedRow}
        visible={openModalLote}
        onClose={() => setOpenModalLote(false)}
      />
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={openDuplicateBackDrop}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Gerando duplicação da atividade...</span>
      </Backdrop>
    </MaterialGrid>
  );
}

GeneralActions.propTypes = {
  actions: PropTypes.shape({
    templatesList: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    templatesLoading: PropTypes.any,
  }),
  actionsDrafts: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  actionsHistory: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
      slice: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    list: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteActionDraft: PropTypes.func,
  deleteActionSuspended: PropTypes.func,
  duplicateAction: PropTypes.func,
  general: PropTypes.any,
  getLazyStationActionsDrafts: PropTypes.func,
  getLazyStationActiveActions: PropTypes.func,
  getLazyStationHistory: PropTypes.func,
  getTemplates: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      to: PropTypes.any,
    }),
  }),
  onClickAction: PropTypes.func,
  onClickActionResults: PropTypes.func,
  onClickActionShare: PropTypes.func,
  onClickDraftAction: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  suspendActivity: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeActions: state.activeActions,
    actionsHistory: state.actionsHistory,
    actionsDrafts: state.actionsDrafts,
    actionsTotal: state.activeActions.actionsTotal,
    draftActionsTotal: state.actionsDrafts.draftActionsTotal,
    actionHistoryTotal: state.actionsHistory.total,
    auth: state.auth,
    actions: state.actions,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLazyStationActiveActions,
      getLazyStationScheduledActions,
      getTotalNumberActiveActions,
      getTotalNumberScheduledActions,
      getLazyStationActionsDrafts,
      getTotalNumberActionsDrafts,
      onClickActionResults,
      onClickActionShare,
      onClickAction,
      suspendActivity,
      onClickDraftAction,
      deleteActionDraft,
      getTemplates,
      getLazyStationHistory,
      getTotalArchivedActions,
      reactivateAction,
      deleteActionSuspended,
      duplicateAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralActions);
