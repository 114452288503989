import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AccountCircle, CalendarTodayOutlined, ScheduleOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import welcome from 'assets/img/welcome.png';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import MaterialCard from 'common/components/cards/materialCard';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import { ActionNameElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FeaturedCard from 'common/components/cards/featuredCard';
import { isMobile } from 'react-device-detect';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { BASE_S3 } from '../../../../../config/consts';
import { getStationData } from '../../dashboard/dashboardOwnerActions';
import { getStationActionsDrafts } from '../actionsDrafts/actionsDraftsScripts';
import { getTemplates } from '../actionsFile';
import {
  getStationActiveActions,
  getStationUserActions,
  onClickActionResults,
  getPrivateFeaturedActivities,
  setFeaturedActivitiesLoad,
} from '../activeActions/activeActionsScripts';
import Home from '../../../../../../src/assets/titlesMobile/home.svg';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

function CardContainer(props) {
  return (
    <div
      style={{
        width: isMobile ? 180 : 270,
        height: isMobile ? 167 : 194,
        background: 'white',
        borderRadius: 8,
        border: '1px solid lightgray',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
      {props.children}
    </div>
  );
}

CardContainer.propTypes = {
  children: PropTypes.any,
};

function CardTitle(props) {
  return (
    <span
      style={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: isMobile ? '12px' : '16px',
        lineHeight: '160%',
        letterSpacing: '0.25px',
        color: '#000000',
      }}>
      {props.children}
    </span>
  );
}

CardTitle.propTypes = {
  children: PropTypes.any,
};
function MainInfo(props) {
  return (
    <span
      style={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: isMobile ? '32px' : '48px',
        lineHeight: '58px',
        letterSpacing: '0.25px',
        color: '#000000',
      }}>
      {props.children}
    </span>
  );
}

MainInfo.propTypes = {
  children: PropTypes.any,
};

function AdditionalInfo(props) {
  return (
    <span
      style={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: isMobile ? '12px' : '16px',
        lineHeight: '160%',
        letterSpacing: '0.25px',
        color: '#000000',
      }}>
      {props.children}
    </span>
  );
}

AdditionalInfo.propTypes = {
  children: PropTypes.any,
};

function ActionLink(props) {
  return (
    <span
      onClick={props.onClick}
      style={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: isMobile ? '12px' : '16px',
        lineHeight: '160%',
        letterSpacing: '0.25px',
        color: '#5759FB',
        cursor: 'pointer',
      }}>
      {props.children}
    </span>
  );
}

ActionLink.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.any,
};

function ActiveActions(props) {
  const { auth } = props;
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState({});
  const [visibleFirst, setVisibleFirst] = useState(false);
  const [userFeaturedActions, setUserFeaturedActions] = useState([]);
  const [featured, setFeatured] = useState(0);
  const [timedOut, setTimedOut] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDays, setSelectedDays] = useState({
    participantes: 7,
    atividadesOnline: 7,
    vendas: 7,
    usuariosAtivos: 7,
  });

  const stationId = auth?.user?.establishments?.[0]?.stations?.[0]?.id;
  const userId = auth?.user?.id;
  const establishmentsId = auth.user.establishments[0]?.id;
  const isBriefingIncomplete = !auth.user.briefingDone;
  const isFirstVisit = props.location.query.first_time;
  const stationUserActions = props.activeActions.stationUserActions;

  const hasPrivateActions = props.activeActions.privateActions.length > 0;
  const privateActions = props.activeActions.privateActions;

  const numbersOfParticipants = props?.dashboardOwner?.data?.users?.length;
  const detailsParticipants = `+${props?.dashboardOwner?.data?.userGrowthDays}% em ${selectedDays.participantes} dias`;
  const numbersOfActionsOnline = props?.dashboardOwner?.data?.actions?.length;
  const detailsActionsOnline =
    parseInt(props?.dashboardOwner?.data?.actionsExpireDays) > 1
      ? `${props?.dashboardOwner?.data?.actionsExpireDays} vencerão em ${selectedDays.atividadesOnline} dias`
      : parseInt(props?.dashboardOwner?.data?.actionsExpireDays) === 1
      ? `${props?.dashboardOwner?.data?.actionsExpireDays} vencerá em ${selectedDays.atividadesOnline} dias`
      : `Nenhuma vencerá em ${selectedDays.atividadesOnline} dias`;
  const numbersOfStationsSales = props?.dashboardOwner?.data?.stationSales;
  const detailsStationsSales = `R$ ${props?.dashboardOwner?.data?.finalValue
    ?.toFixed(2)
    ?.replace('.', ',')} nos últimos ${selectedDays.vendas} dias`;
  const numbersOfActiveUsersDays = props?.dashboardOwner?.data?.activeUsersDays?.length;
  const detailsActiveUsersDays = `nos últimos ${selectedDays.usuariosAtivos} dias`;

  const isLoadingPrivateActions = props.activeActions.loadingPrivateActions;

  useEffect(() => {
    setTimedOut(true);
    setTimeout(() => {
      if (featured >= userFeaturedActions.length - 1) {
        setFeatured(0);
        setTimedOut(false);
      } else {
        setFeatured(featured + 1);
        setTimedOut(false);
      }
    }, 5000);
  }, [userFeaturedActions]);

  useEffect(() => {
    if (!timedOut) {
      setTimedOut(true);
      setTimeout(() => {
        if (featured >= userFeaturedActions.length - 1) {
          setFeatured(0);
          setTimedOut(false);
        } else {
          setFeatured(featured + 1);
          setTimedOut(false);
        }
      }, 5000);
    }
  }, [featured]);

  function onFeaturedChangeFoward() {
    if (featured >= userFeaturedActions.length - 1) {
      setFeatured(0);
      setTimedOut(true);
    } else {
      setFeatured(featured + 1);
      setTimedOut(true);
    }
  }

  function onFeaturedChangeBackwards() {
    if (featured === 0) {
      setFeatured(userFeaturedActions.length - 1);
      setTimedOut(true);
    } else {
      setFeatured(featured - 1);
      setTimedOut(true);
    }
  }

  useEffect(() => {
    if (hasPrivateActions) {
      setUserFeaturedActions(privateActions);
      setFeaturedActivitiesLoad(false);
    }
  }, [privateActions]);

  useEffect(() => {
    props.getStationUserActions(stationId);
    props.getPrivateFeaturedActivities(13, userId);
    props.getTemplates(establishmentsId);
    if (isBriefingIncomplete) {
      props.router.push('/owner/principal');
    }
    if (isFirstVisit) {
      setVisibleFirst(true);
    }
  }, []);

  useEffect(() => {
    const { participantes, atividadesOnline, vendas, usuariosAtivos } = selectedDays;
    props.getStationData(stationId, participantes, atividadesOnline, vendas, usuariosAtivos);
  }, [selectedDays]);

  useEffect(() => {
    setData(stationUserActions);
  }, [stationUserActions]);

  const defaultColumnsMobile = [
    {
      title: '',
      field: 'action.name',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
      },
      render: (rowData) => {
        const userName = rowData?.user?.name;
        const actionName = rowData?.action?.name;
        const image = rowData?.action?.image;
        return (
          <ActionNameElement
            actionName={`${userName} participou da atividade ${actionName}`}
            image={image}
          />
        );
      },
    },
  ];

  const defaultColumns = [
    {
      title: 'Nome da atividade',
      field: 'action.name',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
      },
      render: (rowData) => {
        const actionName = rowData?.action?.name;
        const image = rowData?.action?.image;
        return <ActionNameElement actionName={actionName} image={image} />;
      },
    },
    {
      title: 'Usuário',
      sorting: false,
      field: 'user.name',
      render: (rowData) => {
        const imageUser = rowData?.user?.image;
        const userName = rowData?.user?.name;
        return (
          <div>
            {imageUser ? (
              <img
                style={{
                  height: 35,
                  width: 35,
                  borderRadius: 17.5,
                  marginRight: 10,
                  marginLeft: 4,
                }}
                src={`${BASE_S3}/${imageUser}`}></img>
            ) : (
              <AccountCircle
                style={{
                  fontSize: 40,
                  marginRight: 10,
                  padding: 0,
                }}
                color="action"
              />
            )}
            <span>{userName || ' N/A'}</span>
          </div>
        );
      },
    },
    {
      title: 'Data',
      sorting: false,
      field: 'participationEnd',
      render: (rowData) => (
        <div className="d-flex justify-content-start">
          <div className="d-flex justify-content-start" style={{ marginRight: 10 }}>
            <CalendarTodayOutlined style={{ color: '#323232' }} />
            <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>
          </div>
          <div className="d-flex justify-content-start">
            <ScheduleOutlined style={{ color: '#323232' }} />
            <span>{moment(rowData.createdAt).format('HH:mm')}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Resultados',
      sorting: false,
      field: 'results',
      render: (rowData) => (
        <div className="d-flex justify-content-start">
          <span
            style={{ color: '#2D95EF', cursor: 'pointer' }}
            onClick={() =>
              props.onClickActionResults(rowData.action, props.router, 'inicio') &
              sessionStorage.removeItem('currentPage')
            }>
            Ver resultados
          </span>
        </div>
      ),
    },
  ];
  const [columns, setColumns] = useState(isMobile ? defaultColumnsMobile : defaultColumns);
  useEffect(() => {
    setColumns(isMobile ? defaultColumnsMobile : defaultColumns);
  }, []);

  const renderTemplatesList = () => {
    const templatesBase = props.actions.templatesList.filter(
      (item) => parseInt(item.category) === 1
    );

    if (templatesBase.length > 0) {
      return templatesBase.map((item, index) => {
        return (
          <MaterialGrid item xs={12} key={index} style={{ marginBottom: 10 }}>
            <MaterialCard
              horizontalCard
              onClick={
                item.has_bought
                  ? () => {
                      setOpen(true);
                      setTemplate(item);
                    }
                  : () => props.router.push(`/owner/acoes/${item.id}`)
              }
              name={item.name}
              has_bought={item.has_bought}
              image={`${BASE_S3}/${item.owner_image || item.image}`}
              buttonLabel={item.has_bought ? 'Ativar' : 'Veja Mais'}
              type={item.type.name}
            />
          </MaterialGrid>
        );
      });
    } else {
      return null;
    }
  };

  const renderEmptyCard = () => {
    return (
      <MaterialGrid item xs={12} md={3}>
        <MaterialCard
          horizontalCard
          router={props.router}
          cardEmpty
          onClick={() => {}}
          name={'Abrir loja de atividades'}
          image={``}
          type={''}
        />
      </MaterialGrid>
    );
  };

  const handleDaySelection = (key, value) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [key]: value,
    }));
  };

  function GeneralActivityCard({
    title,
    mainInfo,
    additionalInfo,
    actionLink,
    actionLinkClick,
    actionLink2,
    actionLinkClick2,
    about,
    daysSelected,
    setDaysSelected,
  }) {
    const handleDaySelection = (event) => {
      setDaysSelected(event.target.value);
    };
    return (
      <MaterialGrid item container xs={10} md={3}>
        <CardContainer>
          <div
            className="d-flex flex-row w-100 justify-content-between"
            style={{
              marginLeft: 24,
              marginRight: 24,
              marginBottom: 5,
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
            }}>
            <CardTitle>{title}</CardTitle>
            <div className="d-flex w-100 justify-content-center">
              <FormControl style={{ width: 80 }}>
                <Select value={daysSelected} onChange={handleDaySelection}>
                  {' '}
                  <MenuItem value={7}>7 Dias</MenuItem>
                  <MenuItem value={15}>15 Dias</MenuItem>
                  <MenuItem value={30}>30 Dias</MenuItem>
                  <MenuItem value={60}>60 Dias</MenuItem>
                </Select>
              </FormControl>
            </div>
            {about === 'participantes' ? (
              <ContactMailOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'atividadesOnline' ? (
              <LocalActivityOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'vendas' ? (
              <LocalAtmOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'usuariosAtivos' ? (
              <EmojiEmotionsOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : null}
          </div>
          <div className="d-flex w-100 justify-content-center" style={{ marginBottom: 5 }}>
            <MainInfo>{mainInfo}</MainInfo>
          </div>
          <div className="d-flex w-100 justify-content-center" style={{ marginBottom: 5 }}>
            <AdditionalInfo>{additionalInfo}</AdditionalInfo>
          </div>
          <div className="d-flex w-100 justify-content-center" style={{ marginBottom: 5 }}>
            {/* { actionLink2 } */}
            <ActionLink onClick={actionLinkClick2}>{actionLink2}</ActionLink>
          </div>
          <div className="d-flex w-100 justify-content-center" style={{ marginBottom: 5 }}>
            {/* { actionLink } */}
            <ActionLink onClick={actionLinkClick}>{actionLink}</ActionLink>
          </div>
        </CardContainer>
      </MaterialGrid>
    );
  }

  const renderCardsRow = () => {
    return (
      <>
        <MaterialGrid
          container
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            overflowX: 'scroll',
            overflowY: 'hidden',
            marginBottom: 20,
            width: isMobile ? '90vw' : undefined,
          }}>
          {isMobile ? null : renderEmptyCard()}
          {renderTemplatesList()}
        </MaterialGrid>
      </>
    );
  };

  if (
    props.activeActions.loading ||
    props.actionsDrafts.loading ||
    props.activeActions.loadingStationUserActions
  ) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (
    props.activeActions.loading ||
    props.activeActions.loadingStationUserActions ||
    props.dashboardOwner.dataLoading
  ) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <>
      <div className="row pt-2 mb-4">
        {isMobile ? (
          <div className="d-flex justify-content-start align-items-center container-fluid">
            <div style={{ width: 23, height: 23 }}>
              <img src={Home} alt="home" style={{ width: 23, height: 'auto' }} />
            </div>
            <h4
              style={{
                fontSize: 20,
                fontWeight: 400,
                margin: 0,
                marginLeft: 15,
              }}>
              Painel
            </h4>
          </div>
        ) : null}

        {isLoadingPrivateActions ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100%', width: '100%' }}>
            <LoadingProgress customColor="lightgray" />
          </div>
        ) : userFeaturedActions.length > 0 ? (
          userFeaturedActions[featured].userActions.length < 1 ? (
            <div className="d-flex justify-content-center align-items-center container-fluid">
              <FeaturedCard
                image={userFeaturedActions[featured].image}
                title={userFeaturedActions[featured].type.name}
                text={userFeaturedActions[featured].name}
                total={userFeaturedActions}
                active={featured}
                setActive={(itemfeatured) => {
                  setFeatured(itemfeatured);
                  setTimedOut(true);
                }}
                summary={userFeaturedActions[featured].summary}
                callToAction={userFeaturedActions[featured].click_me_label || 'Participe'}
                reward={`Ganhe ${parseFloat(userFeaturedActions[featured].points)
                  .toFixed(2)
                  .replace('.', ',')} reais`}
                onClick={() => {
                  window.open(
                    `https://rilato.com.br/#/homepage/atividades/${userFeaturedActions[featured].id}`
                  );
                }}
                onFowardClick={onFeaturedChangeFoward}
                onBackwardsClick={onFeaturedChangeBackwards}
                textColor={'#ffffff'}
              />
            </div>
          ) : null
        ) : null}
      </div>
      <MaterialGrid
        container
        spacing={2}
        style={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
          overflowX: isMobile ? 'scroll' : null,
          marginBottom: 20,
          justifyContent: 'space-between',
          maxWidth: '90vw',
        }}>
        {/* Participantes */}
        <GeneralActivityCard
          about={'participantes'}
          title={'Participantes'}
          mainInfo={numbersOfParticipants?.toLocaleString('pt-BR')}
          additionalInfo={detailsParticipants}
          actionLink={'Ver participantes'}
          actionLinkClick={() => props.router.push('/owner/contacts')}
          daysSelected={selectedDays.participantes}
          setDaysSelected={(value) => handleDaySelection('participantes', value)}
        />
        {/* Atividades online */}
        <GeneralActivityCard
          about={'atividadesOnline'}
          title={'Atividades online'}
          mainInfo={numbersOfActionsOnline?.toLocaleString('pt-BR')}
          actionLink={'Ver atividades'}
          actionLinkClick={() => props.router.push('/owner/acoes/minhas-atividades')}
          daysSelected={selectedDays.atividadesOnline}
          actionLink2={detailsActionsOnline}
          actionLinkClick2={() => props.router.push('/owner/acoes/minhas-atividades-validade')}
          setDaysSelected={(value) => handleDaySelection('atividadesOnline', value)}
        />
        {/* Vendas */}
        <GeneralActivityCard
          about={'vendas'}
          title={'Vendas'}
          mainInfo={numbersOfStationsSales?.toLocaleString('pt-BR')}
          additionalInfo={detailsStationsSales}
          actionLink={'Ver pedidos'}
          actionLinkClick={() => props.router.push('/owner/sales')}
          daysSelected={selectedDays.vendas}
          setDaysSelected={(value) => handleDaySelection('vendas', value)}
        />
        {/* Usuários ativos */}
        <GeneralActivityCard
          about={'usuariosAtivos'}
          title={'Usuários ativos'}
          mainInfo={numbersOfActiveUsersDays?.toLocaleString('pt-BR')}
          additionalInfo={detailsActiveUsersDays}
          actionLink={'Criar nova atividade'}
          actionLinkClick={() => props.router.push('/owner/acoes')}
          daysSelected={selectedDays.usuariosAtivos}
          setDaysSelected={(value) => handleDaySelection('usuariosAtivos', value)}
        />
      </MaterialGrid>
      {renderCardsRow()}
      <div
        style={{
          width: isMobile ? '89vw' : undefined,
        }}>
        <MaterialGrid container spacing={3}>
          <MaterialGrid item xs={12}>
            <Table
              hasToolbar={true}
              defaultSelected={''}
              mobileTable={isMobile}
              dataType={'actionsMenu'}
              toolbarSearch
              title="Últimas Participações"
              columns={columns}
              rows={data}
              onChangeOption={() => {}}
              selectOptions={[]}
              hasBorder={!isMobile}
            />
          </MaterialGrid>

          <ConfirmationCard
            onClose={() => setOpen(false)}
            open={open}
            item={template.id}
            station_id={stationId}
            partner_id={null}
            sponsored={null}
            router={props.router}
          />
          {/* <GenericConfirmationCard
          onClose={() => setOpen(false)}
          open={open}
          item={template.id}
          station_id={props.auth.user.establishments[0].stations[0].id}
          partner_id={null}
          sponsored={null}
          router={props.router}

          title="Criação de nova atividade?"
          text="Deseja criar uma nova atividade?"
          labelLeftButton="Cancelar"
          labelRightButton="Confirmar"
        /> */}
        </MaterialGrid>

        <AlertDialogSlide
          visible={visibleFirst}
          title="Seja Bem Vindo"
          onClose={() => {
            setVisibleFirst(false); /*
            props.dispatchStartOnboarding(); */
          }}>
          <MaterialGrid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <MaterialGrid item md={6} lg={6}>
              <img alt="" className="img-fluid" src={welcome}></img>
            </MaterialGrid>
            <MaterialGrid item md={6} lg={6}>
              <Typography variant="h5">Seja bem vindo!</Typography>
              <Typography variant="subtitle1">Agora você faz parte da fan.</Typography>
              <Typography variant="body2">
                <br />
                <p>Aproveite!</p>
              </Typography>
            </MaterialGrid>
          </MaterialGrid>
        </AlertDialogSlide>
      </div>
    </>
  );
}

ActiveActions.propTypes = {
  about: PropTypes.string,
  actionLink: PropTypes.any,
  actionLinkClick: PropTypes.any,
  actions: PropTypes.shape({
    templatesList: PropTypes.shape({
      filter: PropTypes.func,
    }),
  }),
  actionsDrafts: PropTypes.shape({
    loading: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    loading: PropTypes.any,
    loadingPrivateActions: PropTypes.any,
    loadingStationUserActions: PropTypes.any,
    privateActions: PropTypes.shape({
      length: PropTypes.number,
    }),
    stationUserActions: PropTypes.any,
  }),
  additionalInfo: PropTypes.any,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      briefingDone: PropTypes.any,
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  classes: PropTypes.any,
  dashboardOwner: PropTypes.shape({
    data: PropTypes.shape({
      actions: PropTypes.shape({
        length: PropTypes.any,
      }),
      actionsExpire7days: PropTypes.any,
      activeUsers7Days: PropTypes.shape({
        length: PropTypes.any,
      }),
      finalValue: PropTypes.shape({
        toFixed: PropTypes.func,
      }),
      stationSales: PropTypes.any,
      userGrowth7Days: PropTypes.any,
      users: PropTypes.shape({
        length: PropTypes.any,
      }),
    }),
    dataLoading: PropTypes.any,
  }),
  getPrivateFeaturedActivities: PropTypes.func,
  getStationData: PropTypes.func,
  getStationUserActions: PropTypes.func,
  getTemplates: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_time: PropTypes.any,
    }),
  }),
  mainInfo: PropTypes.any,
  onClickActionResults: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  title: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    actionsDrafts: state.actionsDrafts,
    actions: state.actions,
    dashboardOwner: state.dashboardOwner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationActiveActions,
      getStationActionsDrafts,
      getTemplates,
      onClickActionResults,
      getStationUserActions,
      getPrivateFeaturedActivities,
      getStationData,
      setFeaturedActivitiesLoad,
    },
    dispatch
  );
};

const StyledActiveActions = withStyles(styles)(ActiveActions);
export default connect(mapStateToProps, mapDispatchToProps)(StyledActiveActions);

StyledActiveActions.propTypes = {
  auth: PropTypes.objectOf(PropTypes.object).isRequired,
  activeActions: PropTypes.objectOf(PropTypes.object).isRequired,
  actionsDrafts: PropTypes.objectOf(PropTypes.object).isRequired,
  actions: PropTypes.objectOf(PropTypes.object).isRequired,
  dashboardOwner: PropTypes.objectOf(PropTypes.object).isRequired,
  getStationActiveActions: PropTypes.func.isRequired,
  getStationActionsDrafts: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  onClickActionResults: PropTypes.func.isRequired,
  getStationUserActions: PropTypes.func.isRequired,
  getPrivateFeaturedActivities: PropTypes.func.isRequired,
  getStationData: PropTypes.func.isRequired,
  setFeaturedActivitiesLoad: PropTypes.func.isRequired,
};
