import PropTypes from 'prop-types';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { MaterialCheckbox } from 'common/components/fields/MaterialCheckbox';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { getList as getCategoriesList } from 'modules/owner/pages/categories/categoriesActions';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, initialize, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';

const changeFieldValue = (form, value) => {
  return (dispatch) => {
    dispatch(initialize(form, value));
  };
};

const renderOptions = (props) => {
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);
  return (
    <ul style={{ listStyleType: 'none' }}>
      {props.fields.map((option, index) => (
        <li key={index}>
          <Box display="flex" alignContent="center" justifyContent="space-between">
            {props.questionType > 3 && (
              <FontAwesomeIcon
                style={{ margin: 'auto 8', fontSize: 16 }}
                icon={props.questionType === 4 ? faCircle : faSquare}
              />
            )}
            <Field
              component={InputLabelOutlined}
              label={`Opção ${index + 1}`}
              name={`${option}.label.text`}
              validate={[FORM_RULES.required]}
              required
              style={{ width: '100%' }}
              margin="normal"
              disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
            />
            {!(props.disabled || props.router.params.action === 'proposta' || isDisabled) && (
              <FontAwesomeIcon
                style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                icon={faTrash}
                onClick={() => (isDisabled ? null : props.fields.remove(index))}
              />
            )}
          </Box>
          {props.router.params.action === 'editar' ||
          props.router.params.action === 'proposta' ||
          props.router.params.action === 'rascunho' ? (
            props.contactsSurveys.detail.type_id === 6 ? (
              <Box display="flex" alignContent="center" justifyContent="center">
                <Field
                  component={MaterialCheckbox}
                  label={`Esta opção é uma resposta correta?`}
                  name={`${option}.correct`}
                  disabled={
                    props.disabled || props.router.params.action === 'proposta' || isDisabled
                  }
                />
              </Box>
            ) : null
          ) : props.contactsSurveys.objTemplate.type_action_id === 6 ? (
            <Box display="flex" alignContent="center" justifyContent="center">
              <Field
                component={MaterialCheckbox}
                label={`Esta opção é uma resposta correta?`}
                name={`${option}.correct`}
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
              />
            </Box>
          ) : null}
        </li>
      ))}
      {props.contactsSurveys.detail.type_id === 8 &&
        props.formValues.questions[props.questionIndex] &&
        (parseInt(props.formValues.questions[props.questionIndex].type) === 4 ||
          parseInt(props.formValues.questions[props.questionIndex].type) === 5) && (
          <Box display="flex" justifyContent="flex-start">
            <Field component={InputSwitch} label={''} name={`${props.question}.otherOption`} />
            <Field
              component={InputLabelOutlined}
              disabled
              label={`Outro`}
              name={`other_example_field`}
            />
          </Box>
        )}
      <Box display="flex" justifyContent="flex-start" style={{ marginBottom: 10 }}>
        <Typography
          onClick={() => (isDisabled ? null : props.fields.push())}
          style={{
            cursor: 'pointer',
            color: '#5759FB',
            fontSize: 16,
            marginRight: 5,
          }}>
          Adicionar resposta
        </Typography>
      </Box>
    </ul>
  );
};

const renderQuestions = (props) => {
  function array_move(fromIndex, toIndex) {
    const element = props.formValues?.questions[fromIndex];
    props.fields.splice(fromIndex, 1);
    props.fields.splice(toIndex, 0, element);
  }
  function goUp(index) {
    return array_move(index, index - 1);
  }
  function goDown(index) {
    return array_move(index, index + 1);
  }
  const researchOptionsList = [
    {
      value: 4,
      label: 'Múltipla escolha',
    },
    {
      value: 5,
      label: 'Caixa de seleção',
    },
    {
      value: 1,
      label: 'Lista suspensa - uma opção',
    },
    {
      value: 2,
      label: 'Lista suspensa - várias opções',
    },
    {
      value: 3,
      label: 'Questão aberta',
    },
    {
      value: 6,
      label: 'Enviar imagem',
    },
  ];
  const quizOptionsList = [
    {
      value: 4,
      label: 'Múltipla escolha',
    },
    {
      value: 5,
      label: 'Caixa de seleção',
    },
    {
      value: 1,
      label: 'Lista suspensa - uma opção',
    },
    {
      value: 2,
      label: 'Lista suspensa - várias opções',
    },
  ];

  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);

  return (
    <ul style={{ listStyleType: 'none' }}>
      {props.fields.map((question, index) => (
        <Box
          display="flex"
          key={index}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            borderTop: '2px solid #E5E5E5',
            paddingTop: 5,
          }}>
          <Box display="flex" flexDirection="column" style={{ marginRight: 20, zIndex: 100 }}>
            {index !== 0 && props.fields.length > 1 ? (
              <ArrowDropUp style={{ cursor: 'pointer' }} onClick={() => goUp(index)} />
            ) : null}
            {index !== props.fields.length - 1 && props.fields.length > 1 ? (
              <ArrowDropDown style={{ cursor: 'pointer' }} onClick={() => goDown(index)} />
            ) : null}
          </Box>
          <ExpansionPanel
            key={index}
            label={props.formValues?.questions[index]?.question?.text || `Pergunta ${index + 1}`}
            footer={null}
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#ffffff',
            }}>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12} sm={12}>
                <Box display="flex" justifyContent="space-between" style={{ marginBottom: 10 }}>
                  <Typography
                    style={{
                      fontSize: 16,
                    }}>
                    Pergunta
                  </Typography>
                  <Typography
                    style={{
                      cursor: 'pointer',
                      color: '#E34848',
                      fontSize: 16,
                    }}
                    onClick={() => (isDisabled ? null : props.fields.remove(index))}>
                    Excluir pergunta
                  </Typography>
                </Box>
                <Field
                  component={InputLabelOutlined}
                  label={`Pergunta ${index + 1}`}
                  name={`${question}.question.text`}
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                  disabled={
                    props.disabled || props.router.params.action === 'proposta' || isDisabled
                  }
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={SelectLabel}
                  type="text"
                  label={`Tipo da Pergunta ${index + 1}`}
                  name={`${question}.type`}
                  margin="normal"
                  options={
                    props.router.params.action === 'editar' ||
                    props.router.params.action === 'rascunho'
                      ? props.contactsSurveys.detail.type_id === 6
                        ? quizOptionsList
                        : researchOptionsList
                      : props.contactsSurveys.objTemplate.type_action_id === 6
                      ? quizOptionsList
                      : researchOptionsList
                  }
                  validate={[FORM_RULES.required]}
                  disabled={
                    props.disabled || props.router.params.action === 'proposta' || isDisabled
                  }
                />
                {props.formValues.questions[index] &&
                parseInt(props.formValues.questions[index].type) !== 3 &&
                parseInt(props.formValues.questions[index].type) !== 6 ? (
                  <FieldArray
                    name={`${question}.options`}
                    component={renderOptions}
                    question={question}
                    questionIndex={index}
                    formValues={props.formValues}
                    router={props.router}
                    questionType={props.formValues.questions[index].type}
                    contactsSurveys={props.contactsSurveys}
                    disabled={
                      props.disabled || props.router.params.action === 'proposta' || isDisabled
                    }
                  />
                ) : null}
              </MaterialGrid>
            </MaterialGrid>
          </ExpansionPanel>
        </Box>
      ))}
      <Box
        onClick={() => (isDisabled ? null : props.fields.push())}
        style={{
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 26,
        }}>
        <Typography
          style={{
            color: '#5759FB',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
          }}>
          + Adicionar Pergunta
        </Typography>
      </Box>
    </ul>
  );
};
let ResearchActionInteraction = function (props) {
  function onSubmit(values) {
    const newValues = {
      ...values,
      categories: values.categories_field.map((item) => item.id),
      groupings: values.groupings_field.map((item) => item.id),
      submitFromButton: true,
      is_election: values.isElection,
    };
    props.onClickProgress(newValues);
  }
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);

  function renderSteps() {
    return (
      <>
        {_.isEmpty(props.formValues?.questions) && (
          <p style={{ textAlign: 'center' }}>Ainda não há perguntas. Adicione algumas!</p>
        )}
        {props.contactsSurveys.detail.type_id === 8 && (
          <MaterialGrid item xs={12} sm={12} mar>
            <Box mb={2}>
              <Field
                component={InputSwitchLarge}
                label="A atividade é uma eleição?"
                name="isElection"
                disabled={isDisabled}
              />
            </Box>
          </MaterialGrid>
        )}
        <ExpansionList>
          <FieldArray name="questions" component={renderQuestions} {...props} />
        </ExpansionList>
        {props.contactsSurveys.detail.type_id === 6 ? (
          <MaterialGrid item xs={12} style={{ padding: 10, margin: '5px 0px 15px 0px' }}>
            <Field
              component={InputSwitchLarge}
              label={'Resposta errada conta como participação?'}
              name="error_count_as_participation"
              required
              margin="normal"
              disabled={props.router.params.action === 'proposta' || isDisabled}
            />
          </MaterialGrid>
        ) : null}
        <GenericConfirmationCard
          open={props.openConfirmation}
          cancelClose={props.handleCancelClose}
          maxWidthFixed
          labelLeftButton={'Continuar aqui'}
          labelRightButton={props.buttonConfirmationLabel}
          general={props.general}
          confirmButtonColor={'primary'}
          title={props.confirmationTitle}
          text={props.confirmationSubtitle}
          onClose={() => props.setOpenConfirmation(false)}
          onClick={props.closeModal}
        />
      </>
    );
  }

  return props.contactsSurveys.loading ? (
    <div className="d-flex justify-content-center mt-5">
      <LoadingProgress />
    </div>
  ) : (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          {renderSteps()}
        </div>
        <MaterialGrid
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            color="primary"
            disabled={props.activeActions.buttonLoading || isDisabled}
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}>
            Salvar e continuar
          </PrimaryButton>
        </MaterialGrid>
      </Form>
    </>
  );
};

ResearchActionInteraction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  contactsSurveys: PropTypes.shape({
    detail: PropTypes.shape({
      type_id: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  formValues: PropTypes.shape({
    questions: PropTypes.any,
  }),
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setOpenConfirmation: PropTypes.func,
};

ResearchActionInteraction = reduxForm({
  form: 'contactsSurveysForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ResearchActionInteraction);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contactsSurveys: state.contactsSurveys,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('contactsSurveysForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeFieldValue, getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchActionInteraction);
