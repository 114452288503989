import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { destroy, initialize, reset as resetForm } from 'redux-form';
import { calculateRemainingCash } from '../../../../../helpers/utils';
import { onClickDraftAction } from '../actionsDrafts/actionsDraftsScripts';
import types from './types';

const URL_ACTION_PAGE_TABLE = `${BASE_API}/actions-list-table`;
const URL_ACTIONS_BY_ID = `${BASE_API}/actions`;
const URL_STATION_USER_ACTIONS = `${BASE_API}/user-actions-station`;
const URL_ACTION = `${BASE_API}/actions`;
const URL_SHARE_HISTORY_ACTION = `${BASE_API}/history-share-action`;
const URL_ACTION_ARCHIVE = `${BASE_API}/archive-action`;
const URL_ACTION_PUBLISH = `${BASE_API}/publish-action`;
const URL_SEND_MAIL_TEST = `${BASE_API}/send-mail-to-test`;
const URL_SEND_MAIL_PARTICIPANTS = `${BASE_API}/send-mail-to-participants`;
const URL_SEND_PUSH_PARTICIPANTS = `${BASE_API}/send-push-to-participants`;
const URL_SEGMENTED_USERS = `${BASE_API}/get-segmented-users`;
const URL_ACTION_RESULTS = `${BASE_API}/action-results`;
const URL_DUPLICATE = `${BASE_API}/duplicate-action-station`;
const URL_ACTION_TOTAL_PAGE = `${BASE_API}/actions/total`;

export const getStationActiveActions = (id) => {
  return (dispatch) => {
    dispatch({ type: 'ACTIVE_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE_TABLE}/?where[active]=1&&where[station_id]=${id}&&where[is_draft]=0`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        for (const i in response.data) {
          response.data[i].createdAtDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].startDate = moment(response.data[i].start).format('DD/MM/YYYY HH:mm');
          response.data[i].endDate = moment(response.data[i].end).format('DD/MM/YYYY HH:mm');
          response.data[i].participationStartDate = moment(
            response.data[i].participationStart
          ).format('DD/MM/YYYY HH:mm');
          response.data[i].participationEndDate = moment(response.data[i].participationEnd).format(
            'DD/MM/YYYY HH:mm'
          );
          response.data[i].totalSpendAction =
            parseFloat(response.data[i].limit) * parseFloat(response.data[i].points);
          let currentSpendAction = 0;
          for (const transaction of response.data[i].earn_points_transactions) {
            if (transaction.points) {
              currentSpendAction += parseFloat(transaction.points);
            }
          }
          response.data[i].currentSpendAction = currentSpendAction;
          response.data[i].remainingCashAction = calculateRemainingCash(
            response.data[i].userActions.length,
            parseFloat(response.data[i].limit),
            parseInt(response.data[i].type_id) === 1 || parseInt(response.data[i].type_id) === 19
              ? parseFloat(response.data[i].member_get_members?.[0]?.points_inviter) +
                  parseFloat(response.data[i].member_get_members?.[0]?.points_invited)
              : parseFloat(response.data[i].points)
          );
        }
        dispatch([
          {
            type: 'ACTIVE_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getStationUserActions = (id) => {
  return (dispatch) => {
    dispatch({ type: 'STATION_USER_ACTIONS_LOAD', payload: true });
    axios
      .get(`${URL_STATION_USER_ACTIONS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'STATION_USER_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getLazyStationScheduledActions = (id, lazy) => {
  return getLazyActiveActions(id, lazy, 1);
};

export const getLazyStationActiveActions = (id, lazy) => {
  return getLazyActiveActions(id, lazy, 0);
};

const getLazyActiveActions = (id, lazy, is_scheduled) => {
  return (dispatch) => {
    dispatch({ type: 'ACTIVE_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE_TABLE}/?where[active]=1&&where[station_id]=${id}&&where[is_draft]=0\
        &&where[station_id]=notnull&&where[limit]=${lazy.rows}&&where[offset]=${lazy.page}\
        &&where[order]=${lazy.sortField} ${lazy.sortOrder}\
        &&where[is_scheduled]=${is_scheduled}\
        &&where[filters]=${JSON.stringify(lazy.filters)}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['startDate'] = moment(response.data[i].start).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['endDate'] = moment(response.data[i].end).format('DD/MM/YYYY HH:mm:ss');
        }
        dispatch([
          {
            type: 'ACTIVE_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getLazyStationActiveActionsExpiration = (id, lazy, dias) => {
  return getLazyActiveActionsExpiration(id, lazy, 0, dias);
};

const getLazyActiveActionsExpiration = (id, lazy, is_scheduled, dias) => {
  return (dispatch) => {
    dispatch({ type: 'ACTIVE_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE_TABLE}-expiration/?where[active]=1&&where[station_id]=${id}&&where[is_draft]=0\
        &&where[station_id]=notnull&&where[limit]=${lazy.rows}&&where[offset]=${lazy.page}\
        &&where[order]=${lazy.sortField} ${lazy.sortOrder}\
        &&where[is_scheduled]=${is_scheduled}\
        &&where[filters]=${JSON.stringify(lazy.filters)}
        &&where[dias]=${dias}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['startDate'] = moment(response.data[i].start).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['endDate'] = moment(response.data[i].end).format('DD/MM/YYYY HH:mm:ss');
        }
        dispatch([
          {
            type: 'ACTIVE_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getTotalNumberActiveActions = (id, lazy) => {
  return getTotalNumberActions(id, lazy, 0);
};

export const getTotalNumberScheduledActions = (id, lazy) => {
  return getTotalNumberActions(id, lazy, 1);
};

const getTotalNumberActions = (id, lazy, is_scheduled) => {
  return (dispatch) => {
    dispatch({ type: 'ACTIVE_ACTIONS_TOTAL_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_TOTAL_PAGE}/?where[active]=1&&where[station_id]=${id}&&where[is_draft]=0\
        &&where[station_id]=notnull&&where[is_scheduled]=${is_scheduled}\
        &&where[filters]=${JSON.stringify(lazy.filters)}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'ACTIVE_ACTIONS_TOTAL_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const onClickAction = (action, router = undefined, previousRoute = undefined) => {
  return (dispatch) => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/editar/?from=${previousRoute}`
        );
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/curso-presencial/${action.id}/editar/?from=${previousRoute}`);
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 9) {
      router.push(`/owner/acoes/checkList/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 10) {
      router.push(`/owner/acoes/pollQuiz/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 11) {
      router.push(`/owner/acoes/rightAnswer/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 12) {
      router.push(`/owner/acoes/quiz-personalidade/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 1) {
      router.push(`/owner/acoes/member-get-member/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 19) {
      router.push(`/owner/acoes/member-get-permission/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 13) {
      router.push(`/owner/acoes/redirecionar-link/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 14) {
      router.push(`/owner/acoes/entregar-produto/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 15) {
      router.push(`/owner/acoes/agreement/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/${action.id}/editar/?from=${previousRoute}`);
    } else if (action.type_id === 20) {
      router.push(
        `/owner/acoes/redirecionar-link-area-interna/${action.id}/editar/?from=${previousRoute}`
      );
    } else if (action.type_id === 21) {
      router.push(`/owner/acoes/assinar-produto/${action.id}/editar/?from=${previousRoute}`);
    }
  };
};

export const onClickActionResults = (action, router = undefined, previousRoute = undefined) => {
  return (dispatch) => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/aquisicao-clientes/pesquisa/resultados/?action=${action.id}&from=${previousRoute}`
        );
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/curso-presencial/resultados/?action=${action.id}&from=${previousRoute}`
        );
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 9) {
      router.push(`/owner/acoes/checkList/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 10) {
      router.push(`/owner/acoes/pollQuiz/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 11) {
      router.push(`/owner/acoes/rightAnswer/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 12) {
      router.push(
        `/owner/acoes/quiz-personalidade/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 1) {
      router.push(
        `/owner/acoes/member-get-member/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 19) {
      router.push(
        `/owner/acoes/member-get-permission/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 13) {
      router.push(
        `/owner/acoes/redirecionar-link/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 14) {
      router.push(
        `/owner/acoes/entregar-produto/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 15) {
      router.push(`/owner/acoes/agreement/resultados/?action=${action.id}&from=${previousRoute}`);
    } else if (action.type_id === 16) {
      router.push(
        `/owner/acoes/conteudo-s-i/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 17) {
      router.push(
        `/owner/acoes/atividade-doacao/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 18) {
      router.push(
        `/owner/acoes/video-playlist/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 20) {
      router.push(
        `/owner/acoes/redirecionar-link-area-interna/resultados/?action=${action.id}&from=${previousRoute}`
      );
    } else if (action.type_id === 21) {
      router.push(
        `/owner/acoes/assinar-produto/resultados/?action=${action.id}&from=${previousRoute}`
      );
    }
  };
};

export const onClickActionShare = (action, router = undefined) => {
  return (dispatch) => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/aquisicao-clientes/pesquisa/divulgar/?action=${action.id}`);
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/curso-presencial/divulgar/?action=${action.id}`);
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/divulgar/?action=${action.id}`);
    } else if (action.type_id === 9) {
      router.push(`/owner/acoes/checkList/divulgar/?action=${action.id}`);
    } else if (action.type_id === 10) {
      router.push(`/owner/acoes/pollQuiz/divulgar/?action=${action.id}`);
    } else if (action.type_id === 11) {
      router.push(`/owner/acoes/rightAnswer/divulgar/?action=${action.id}`);
    } else if (action.type_id === 12) {
      router.push(`/owner/acoes/quiz-personalidade/divulgar/?action=${action.id}`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/divulgar/?action=${action.id}`);
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/divulgar/?action=${action.id}`);
    } else if (action.type_id === 1) {
      router.push(`/owner/acoes/member-get-member/divulgar/?action=${action.id}`);
    } else if (action.type_id === 19) {
      router.push(`/owner/acoes/member-get-permission/divulgar/?action=${action.id}`);
    } else if (action.type_id === 13) {
      router.push(`/owner/acoes/redirecionar-link/divulgar/?action=${action.id}`);
    } else if (action.type_id === 14) {
      router.push(`/owner/acoes/entregar-produto/divulgar/?action=${action.id}`);
    } else if (action.type_id === 15) {
      router.push(`/owner/acoes/agreement/divulgar/?action=${action.id}`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/divulgar/?action=${action.id}`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/divulgar/?action=${action.id}`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/divulgar/?action=${action.id}`);
    } else if (action.type_id === 20) {
      router.push(`/owner/acoes/redirecionar-link-area-interna/divulgar/?action=${action.id}`);
    } else if (action.type_id === 21) {
      router.push(`/owner/acoes/assinar-produto/divulgar/?action=${action.id}`);
    }
  };
};

export const publishAction = (id, router, formName, loadType) => {
  return (dispatch) => {
    axios
      .put(
        `${URL_ACTION_PUBLISH}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: loadType,
            payload: false,
          },
        ]);
        router.push('/owner/acoes/minhas-atividades');
        if (formName) dispatch(destroy(formName));
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      })
      .finally(() => {
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: loadType,
            payload: false,
          },
          {
            type: 'ACTIVE_ACTIONS_BUTTON_LOAD',
            payload: false,
          },
        ]);
      });
  };
};

export const setFeaturedActivitiesLoad = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: 'FEATURED_PRIVATE_ACTIVITIES_LOAD',
      payload: boolean,
    });
  };
};

export const getPrivateFeaturedActivities = (id, user) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'FEATURED_PRIVATE_ACTIVITIES_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(
        `${URL_ACTIONS_BY_ID}/?where[featuredPrivate]=1&&where[station_id]=${id}&&where[user_id]=${user}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'FEATURED_PRIVATE_ACTIVITIES_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          toastr.error(e.response.data.messages[0], 'Erro');
        } catch {
          toastr.error('Erro');
        }
      });
  };
};

export const reset = (form) => {
  return resetForm(form);
};

export const initializeForm = (form, values) => {
  return initialize(form, values);
};

export const suspendActivity = (id, router, shouldReload = false) => {
  return (dispatch) => {
    axios
      .put(
        `${URL_ACTION_ARCHIVE}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(() => {
        router.push('/owner/acoes/minhas-atividades/?to=closed');
        if (shouldReload) {
          window.location.reload();
        }
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const duplicateAction = (action, router, setBackDrop) => {
  return (dispatch) => {
    setBackDrop(true);
    axios
      .post(
        `${URL_DUPLICATE}/${action.id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([onClickDraftAction(response.data, router), setBackDrop(false)]);
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }

          setBackDrop(false);
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });

          setBackDrop(false);
        }
      });
  };
};

export const getActionDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.DETAIL_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.DETAIL_FETCHED,
            payload: response.data,
          },
        ]);
      });
  };
};

export const sendTestMail = (values) => {
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_SEND_MAIL_TEST}/${values.action_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch((e) => {})
      .finally(() => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      });
  };
};

export const getActionShareHistory = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'ACTION_SHARE_HISTORY_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_SHARE_HISTORY_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ACTION_SHARE_HISTORY_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getSegmentedUsers = (action_id, station_id) => {
  return (dispatch) => {
    dispatch({
      type: 'ACTION_SHARE_SEGMENTED_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_SEGMENTED_USERS}/${action_id}/${station_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ACTION_SHARE_SEGMENTED_FETCHED',
            payload: response.data.usersToSend,
          },
        ]);
      });
  };
};

export const sendMailParticipants = (
  action_id,
  alternativeSummary,
  alternativeSubject,
  actionImage
) => {
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(
        `${URL_SEND_MAIL_PARTICIPANTS}/${action_id}`,
        {
          image: actionImage,
          alternative_summary: alternativeSummary,
          alternative_subject: alternativeSubject,
        },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {})
      .catch((e) => {})
      .finally(() => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      });
  };
};

export const sendPushParticipants = (values, action_id, scheduledTime, isScheduled) => {
  const payload = {
    ...values,
    scheduledTime,
  };

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: types.BUTTON_LOAD, payload: true });
      dispatch({ type: 'SUBMIT_PROGRESS', payload: 1 });

      if (isScheduled) {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Agendamento realizado com sucesso!',
              variant: 'success',
              open: true,
            },
          },
        ]);
      }

      axios
        .post(`${URL_SEND_PUSH_PARTICIPANTS}/${action_id}`, payload, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((response) => {
          dispatch([
            { type: types.BUTTON_LOAD, payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Push enviado com sucesso!',
                variant: 'success',
                open: true,
              },
            },
          ]);
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message:
                'Erro ao enviar push: ' +
                (error.response
                  ? error.response.data.message
                  : 'Erro interno do servidor, contate o suporte.'),
              variant: 'error',
              open: true,
            },
          });
          reject(error);
        })
        .finally(() => {
          dispatch({ type: types.BUTTON_LOAD, payload: false });
        });
    });
  };
};

export const getActionResults = (action_id) => {
  return (dispatch) => {
    dispatch({
      type: 'ACTION_RESULTS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_ACTION_RESULTS}/${action_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ACTION_RESULTS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};
