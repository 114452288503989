import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';
import { getPartnersList } from '../presentialCoursesActions';

class Interaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmation: false,
    };
    this.props.getPartnersList();
  }

  componentDidUpdate(props) {
    if (this.props.formValues !== props.formValues) {
      if (!this.props.formValues.prices) {
        this.props.formValues.price = 0;
        this.props.formValues.discount_price = 0;
        this.props.formValues.discountPrice = 0;
      }
      if (
        !_.isUndefined(this.props.formValues.price) &&
        !_.isUndefined(this.props.formValues.discountPrice)
      ) {
        this.props.formValues.discount_price =
          this.props.formValues.price - this.props.formValues.discountPrice;
      } else if (
        !_.isUndefined(this.props.formValues.price) &&
        _.isUndefined(this.props.formValues.discountPrice)
      ) {
        this.props.formValues.discount_price = this.props.formValues.price;
      }
    }
  }

  onSubmit = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  render() {
    const atividade = this?.props?.formValues;
    const now = new Date();
    const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);
    const isSindjus = this?.props?.auth?.user.establishments[0].id === 235;

    return this.props.presentialCourses.loading ? (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    ) : (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 15,
            }}>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label={'Data de Expiração do Voucher'}
                  name="date"
                  type="datetime-local"
                  disabled={this.props.router.params.action === 'proposta' || isDisabled}
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              {isSindjus && (
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputSwitchLarge}
                    label={'Esse será um voucher familiar?'}
                    name="familiarVoucher"
                    disabled={isDisabled}
                    margin="normal"
                  />
                </MaterialGrid>
              )}
              <MaterialGrid item xs={12}>
                <Field
                  component={InputSwitchLarge}
                  label={'Preços'}
                  // firstState="Defina o preço original e o desconto"
                  // secondState="Ative para mostrar preços"
                  name="prices"
                  // required
                  margin="normal"
                  disabled={isDisabled}
                />
                {this.props.formValues.prices ? (
                  <MaterialGrid container spacing={3}>
                    <MaterialGrid item xs={4}>
                      <Field
                        component={InputLabelOutlined}
                        type="tel"
                        label={'Valor em reais'}
                        name="price"
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                        disabled={isDisabled}
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={4}>
                      <Field
                        component={InputLabelOutlined}
                        type="tel"
                        label={'Desconto em reais'}
                        name="discountPrice"
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                        disabled={isDisabled}
                      />
                    </MaterialGrid>
                    <MaterialGrid
                      item
                      xs={4}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,
                      }}>
                      <Typography color="primary" style={{ fontSize: 12 }}>
                        Valor com desconto
                      </Typography>
                      <Typography style={{ color: 'black', marginTop: 5 }}>
                        {this.props.formValues
                          ? this.props.formValues.discount_price === 0 ||
                            this.props.formValues.discount_price === '0'
                            ? 'Grátis'
                            : 'R$ ' +
                              parseFloat(this.props.formValues.discount_price || 0)
                                .toFixed(2)
                                .replace('.', ',')
                          : null}
                      </Typography>
                    </MaterialGrid>
                    {this.props.formValues.discount_price === 0 ||
                    this.props.formValues.discount_price === '0' ? null : (
                      <>
                        <MaterialGrid item xs={4}>
                          <Field
                            component={InputLabelOutlined}
                            type="tel"
                            label={'Subsídio'}
                            name="allowance"
                            required
                            validate={[FORM_RULES.required]}
                            margin="normal"
                            {...currencyMask}
                            disabled={isDisabled}
                          />
                        </MaterialGrid>
                        <MaterialGrid
                          item
                          xs={4}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10,
                          }}>
                          <Typography color="primary" style={{ fontSize: 12 }}>
                            Valor final
                          </Typography>
                          <Typography style={{ color: 'black', marginTop: 5 }}>
                            {this.props.formValues
                              ? 'R$ ' +
                                (
                                  parseFloat(this.props.formValues.discount_price || 0) -
                                  (this.props.formValues.allowance || 0)
                                )
                                  .toFixed(2)
                                  .replace('.', ',')
                              : null}
                          </Typography>
                        </MaterialGrid>
                      </>
                    )}
                  </MaterialGrid>
                ) : null}
              </MaterialGrid>

              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  type="text"
                  label={'Regulamento do Voucher'}
                  name="local"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                  disabled={this.props.router.params.action === 'proposta' || isDisabled}
                />
              </MaterialGrid>
            </MaterialGrid>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              maxWidthFixed
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              general={this.props.general}
              confirmButtonColor={'primary'}
              title={this.props.confirmationTitle}
              text={this.props.confirmationSubtitle}
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={() => this.props.router.push('/owner/acoes/minhas-atividades')}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
            }}>
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              disabled={this.props.activeActions.buttonLoading || isDisabled}
              color="primary"
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Interaction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  formValues: PropTypes.shape({
    allowance: PropTypes.number,
    discountPrice: PropTypes.any,
    discount_price: PropTypes.number,
    price: PropTypes.any,
    prices: PropTypes.any,
  }),
  general: PropTypes.any,
  getPartnersList: PropTypes.func,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  presentialCourses: PropTypes.shape({
    loading: PropTypes.any,
  }),
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
    push: PropTypes.func,
  }),
  setOpenConfirmation: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    presentialCourses: state.presentialCourses,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('presentialCoursesForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPartnersList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'presentialCoursesForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Interaction)
);
