import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, initialize, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';
import { currencyMask } from '../../../../../../helpers/masks';
import { getList as getCategoriesList } from '../../../categories/categoriesActions';

const changeFieldValue = (form, value) => {
  return (dispatch) => {
    dispatch(initialize(form, value));
  };
};

const renderproducts = (props) => {
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);
  return (
    <>
      <Box>
        <PrimaryButton
          onClick={() => props.fields.push()}
          disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}>
          Adicionar Produto
        </PrimaryButton>
      </Box>
      {props.fields.map((product, index) => (
        <ExpansionPanel
          key={index}
          label={`Produto ${index + 1}`}
          footer={
            <Box ml={2}>
              <SecondaryButton
                onClick={() => props.fields.remove(index)}
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
                color="primary">
                Excluir Produto
              </SecondaryButton>
            </Box>
          }
          style={{
            marginBottom: 10,
            width: '100%',
            borderRadius: 4,
            boxShadow: 'none',
            backgroundColor: '#ffffff',
          }}>
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label={`Nome do Produto ${index + 1}`}
                name={`${product}.name`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
              />
            </MaterialGrid>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label={`Limite Por Usuário`}
                name={`${product}.limit`}
                validate={[FORM_RULES.required]}
                required
                type="number"
                margin="normal"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
              />
            </MaterialGrid>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label={`Estoque total`}
                name={`${product}.stock`}
                validate={[FORM_RULES.required]}
                required
                type="number"
                margin="normal"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
              />
            </MaterialGrid>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label="Preço (R$)"
                name={`${product}.value`}
                type="tel"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...currencyMask}
              />
            </MaterialGrid>
          </MaterialGrid>
        </ExpansionPanel>
      ))}
    </>
  );
};
const renderLocations = (props) => {
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);
  return (
    <>
      <Box>
        <PrimaryButton
          onClick={() => props.fields.push()}
          disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}>
          Adicionar Local de Entrega
        </PrimaryButton>
      </Box>
      {props.fields.map((location, index) => (
        <ExpansionPanel
          key={index}
          label={`Local ${index + 1}`}
          footer={
            <Box ml={2}>
              <SecondaryButton
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
                onClick={() => props.fields.remove(index)}
                color="primary">
                Excluir Local
              </SecondaryButton>
            </Box>
          }
          style={{
            marginBottom: 10,
            width: '100%',
            borderRadius: 4,
            boxShadow: 'none',
            backgroundColor: '#ffffff',
          }}>
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label={`Nome do Local ${index + 1}`}
                name={`${location}.name`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
              />
            </MaterialGrid>
            <MaterialGrid item xs={6} sm={6}>
              <Field
                component={InputLabelOutlined}
                label="Custo da entrega no local (R$)"
                name={`${location}.price`}
                type="tel"
                disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...currencyMask}
              />
            </MaterialGrid>
          </MaterialGrid>
        </ExpansionPanel>
      ))}
    </>
  );
};

let ResearchActionInteraction = function (props) {
  function onSubmit(values) {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    props.onClickProgress(values);
  }
  const atividade = props?.formValues;
  const now = new Date();
  const isDisabled = !atividade?.is_draft && now > new Date(atividade?.end);

  function renderSteps() {
    return (
      <>
        <Typography style={{ marginBottom: 15 }} variant="subtitle2">
          PRODUTOS
        </Typography>
        {_.isEmpty(props.formValues.products) && (
          <p style={{ textAlign: 'center' }}>Ainda não há produtos. Adicione alguns!</p>
        )}
        <ExpansionList>
          {<FieldArray name="products" component={renderproducts} {...props} />}
        </ExpansionList>

        <Typography style={{ marginBottom: 15 }} variant="subtitle2">
          LOCAIS DE ENTREGA
        </Typography>
        <ExpansionList>
          {<FieldArray name="delivery_locations" component={renderLocations} {...props} />}
        </ExpansionList>
        <Typography style={{ marginBottom: 15 }} variant="subtitle2">
          DESCONTO TOTAL NO PEDIDO
        </Typography>
        <MaterialGrid item xs={6} sm={6}>
          <Field
            component={InputLabelOutlined}
            label="Desconto a ser aplicado no pedido (R$)"
            name={`discount_delivery`}
            type="tel"
            disabled={props.disabled || props.router.params.action === 'proposta' || isDisabled}
            validate={[FORM_RULES.required]}
            required
            margin="normal"
            {...currencyMask}
          />
        </MaterialGrid>
        <GenericConfirmationCard
          open={props.openConfirmation}
          cancelClose={props.handleCancelClose}
          maxWidthFixed
          labelLeftButton={'Continuar aqui'}
          labelRightButton={props.buttonConfirmationLabel}
          general={props.general}
          confirmButtonColor={'primary'}
          title={props.confirmationTitle}
          text={props.confirmationSubtitle}
          onClose={() => props.setOpenConfirmation(false)}
          onClick={props.closeModal}
        />
      </>
    );
  }

  return props.productDelivery.loading ? (
    <div className="d-flex justify-content-center mt-5">
      <LoadingProgress />
    </div>
  ) : (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          {renderSteps()}
        </div>
        <MaterialGrid
          container
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            disabled={props.activeActions.buttonLoading || isDisabled}
            color="primary"
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}>
            Salvar e continuar
          </PrimaryButton>
        </MaterialGrid>
      </Form>
    </>
  );
};

ResearchActionInteraction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    products: PropTypes.any,
  }),
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  productDelivery: PropTypes.shape({
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setOpenConfirmation: PropTypes.func,
};

ResearchActionInteraction = reduxForm({
  form: 'productDeliveryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ResearchActionInteraction);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    productDelivery: state.productDelivery,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('productDeliveryForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeFieldValue, getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchActionInteraction);
