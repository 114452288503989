import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const clearSessionStoragePage = () => {
  sessionStorage.removeItem('currentPage');
};

function AlertDialogSlide(props) {
  return (
    <Dialog
      fullScreen={props.isFullScreen}
      open={props.visible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          minHeight: props.height || null,
          minWidth: props.width || null,
          background: props.background || '#fff',
        },
      }}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={props.maxWidth || 'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <MuiDialogTitle
        hidden={props.hiddenTitle || false}
        disableTypography
        style={{
          background: props.titleBackground || '#fff',
        }}>
        <Typography
          variant="h6"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {props.title}
          {props.onClose ? (
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => props.onClose() & clearSessionStoragePage()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Typography>
      </MuiDialogTitle>
      <DialogContent
        style={{
          background: props.contentBackground || '#fff',
        }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

AlertDialogSlide.propTypes = {
  background: PropTypes.string,
  children: PropTypes.any,
  contentBackground: PropTypes.string,
  height: PropTypes.any,
  hiddenTitle: PropTypes.bool,
  isFullScreen: PropTypes.any,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleBackground: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.any,
};

export default AlertDialogSlide;
