import { BASE_API, USER_TOKEN } from 'config/consts';
import axios from 'axios';
import types from './type';

const URL_GET_CAMPAIGNS = `${BASE_API}/actions/establishment`;
const URL_ESTABLISHMENTS = `${BASE_API}/establishments`;
const URL_CUPOM_OFFERS = `${BASE_API}/offer-by-establishment`;
const URL_CUPOM_ACTIVATE = `${BASE_API}/cupom-offer-active`;
const STATION_DATA = `${BASE_API}/station-data`;

export const getCampaignsByStationId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.CAMPAIGNS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_CAMPAIGNS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.CAMPAIGNS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
};

export const dispatchStartOnboarding = () => {
  return (dispatch) => {
    dispatch([
      {
        type: 'START_ONBOARDING',
        payload: true,
      },
    ]);
  };
};

export const getStationData = (id, participantes, atividadesOnline, vendas, usuariosAtivos) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'STATION_DATA_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(
        `${STATION_DATA}/${id}?participantesDays=${participantes}&atividadesOnlineDays=${atividadesOnline}&vendasDays=${vendas}&usuariosAtivosDays=${usuariosAtivos}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'STATION_DATA_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

export const getCupomOffersByStationId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.CUPOM_OFFERS_ESTABLISHMENT_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_CUPOM_OFFERS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.CUPOM_OFFERS_ESTABLISHMENT_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        // console.log(e);
      });
  };
};

export const activateCupom = (id, active) => {
  return (dispatch) => {
    axios
      .post(
        `${URL_CUPOM_ACTIVATE}/${id}`,
        { active },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        // console.log(e);
      });
  };
};

export const getEstablishmentsList = () => {
  return (dispatch) => {
    dispatch([
      {
        type: 'ESTABLISHMENTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ESTABLISHMENTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ESTABLISHMENTS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};
